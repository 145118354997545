import {addOnEventCallback} from '../privates/postMessage'
import {StartConfigurationEvent} from '@wix/editor-platform-sdk-types'

export default function (appData) {
  /**
   * @doc Panel
   * @note `Classic Editor`
   * @example
   * editorSDK.panel.onEvent(({eventType, eventPayload}) => {
   *   switch(eventType) {
   *     case 'startConfiguration':
   *       const {token, initialData} = eventPayload;
   *       break;
   *   }
   * });
   * @param callback - The callback to wait for events.
   * @description This function should be called from every panel.
   * The specified callback accepts the *startConfiguration* event, which includes the panel token and initial data.
   * Save the token to use [`editorSDK.editor.closePanel()`](./Editor.md#closepanel) to close the panel. Learn more in [this article](../articles/application-panels.md).
   */
  function onEvent(callback: (event: StartConfigurationEvent) => void) {
    addOnEventCallback(appData, callback)
  }

  return {
    onEvent,
  }
}
