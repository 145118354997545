import {getAPI} from '../privates/editorAPI'
import {
  DeviceType,
  ConsentPolicy,
  ViewportInfo,
} from '@wix/editor-platform-sdk-types'

export default function (appData) {
  /**
   * @doc editorInfo
   * @note `Classic Editor` `Editor X`
   * @example
   * const ECOM_APP_DEF_ID  = 'mock-app-def-id'
   * const theOtherAppDashboardUrl = await editorSDK.editor.info.getDashboardUrl(token, {
   *   appDefinitionId: ECOM_APP_DEF_ID
   * });
   * @param token - app token - not in use
   * @param options -
   * @param options.appDefinitionId - the other app's app definition ID
   * @description Gets the dashboard URL of the specified app.
   * If no app is specified, returns the dashboard URL of the calling app.
   * @returns A promise that includes the dashboard URL.
   */
  function getDashboardUrl(
    token: string,
    options?: {appDefinitionId: string},
  ): Promise<string> {
    return getAPI().then((api) => {
      return api.editor.info.getDashboardUrl(appData, token, options)
    })
  }

  /**
   * @doc editorInfo
   * @note `Classic Editor` `Editor X`
   * @example
   * const siteBusinessManagerUrl = await editorSDK.editor.info.getBusinessManagerUrl(token)
   * @param token - app token - not in use
   * @description Gets the Business Manager home URL of the site.
   * @returns A promise that includes the home Business Manager URL of the site.
   * The promise is rejected if the site wasn't saved, because Business Manager URL consists of metaSiteId and the unsaved site has template metaSiteId.
   */
  function getBusinessManagerUrl(token: string): Promise<string> {
    return getAPI().then((api) => {
      return api.editor.info.getBusinessManagerUrl(appData, token)
    })
  }

  /**
   * @doc editorInfo
   * @note `Classic Editor` `Editor X`
   * @example
   * const ascend = await editorSDK.editor.info.getAscendUpgradeUrl(token, { origin: 'formSettingsPanel_upgradeTab', slug: 'wix-forms' })
   * @param token - app token - not in use
   * @param options -
   * @param options.origin : the URL's origin.
   * @param options.slug : the app's name.
   * @description Gets the Ascend upgrade URL for the site.
   * @returns A promise to the Ascend Upgrade URL package picker, that contains the upgrade package URL.
   * The promise is rejected if the site wasn't saved, because the Ascend upgrade URL is based on the metaSiteId, which is created on save.
   */
  function getAscendUpgradeUrl(
    token: string,
    options: {origin: string; slug: string},
  ): Promise<string> {
    return getAPI().then((api) => {
      return api.editor.info.getAscendUpgradeUrl(appData, token, options)
    })
  }

  /**
   * @doc editorInfo
   * @note `Classic Editor`
   * @example
   * const editorSessionId = await editorSDK.editor.info.getEditorSessionId(token);
   * @param token - app token - not in use
   * @description Returns the editor Session ID, to be used for BI purposes.
   * @returns A promise that includes the editor session ID.
   */
  function getEditorSessionId(token?: string): Promise<string> {
    return getAPI().then((api) => {
      return api.editor.info.getEditorSessionId(appData, token)
    })
  }

  /**
   * @doc editorInfo
   * @note `Classic Editor`
   * @example const editorReadyTimestamp = await editorSDK.editor.info.getEditorReadyTimestamp(token);
   * @param token - app token - not in use
   * @description Returns the time (in ms) since the editor was loaded.
   * @returns A promise that includes the time since the editor was loaded.
   */
  function getEditorReadyTimestamp(token?: string): Promise<number> {
    return getAPI().then((api) =>
      api.editor.info.getEditorReadyTimestamp(appData, token),
    )
  }

  /**
   * @doc editorInfo
   * @note `Classic Editor` `Editor X`
   * @example
   * const editorMode = await editorSDK.editor.info.getEditorMode(token);
   * @param token - app token - not in use
   * @description Returns the current editor mode - mobile or desktop.
   * @returns A Promise that includes the current editor mode.
   */
  function getEditorMode(token?: string): Promise<DeviceType> {
    return getAPI().then((api) => api.editor.info.getEditorMode(appData, token))
  }

  /**
   * @doc editorInfo
   * @note `Classic Editor`
   * @example
   * const editorReferrer = await editorSDK.editor.info.getEditorReferrer(token);
   * @param token - app token - not in use
   * @description Returns `document.referrer` value from the editor context.
   * @returns A promise that includes the string value of the referrer.
   */
  function getEditorReferrer(token?: string): Promise<DeviceType> {
    return getAPI().then((api) =>
      api.editor.info.getEditorReferrer(appData, token),
    )
  }

  /**
   * @doc editorInfo
   * @note `Classic Editor`
   * @example
   * const editorMode = await editorSDK.editor.info.getConsentPolicy(token);
   * @param token - app token - not in use
   * @description Returns the current consent policy, which defines what types of cookies the user has accepted. The consent policy object is defined [here](https://github.com/wix-private/consent-policy-service/blob/65f41a31b16c040ae6fc889a3c5ff9b3cdf97d4b/cookie-consent-settings-ui/src/types/consent-policy.ts).
   * @returns A promise that resolves with the current consent policy object. If it returns *undefined* it's because the consent policy failed to load.
   */
  function getConsentPolicy(
    token?: string,
  ): Promise<ConsentPolicy | undefined> {
    return getAPI().then((api) =>
      api.editor.info.getConsentPolicy(appData, token),
    )
  }

  /**
   * @doc editorInfo
   * @note `Classic Editor` `Editor X`
   * @example
   * const currentViewport = await editorSDK.editor.info.getCurrentViewport(token)
   * @param token - app token - not in use
   * @description Gets the current viewport selected in the editor.
   * @returns A promise that resolves to a `ViewportInfo` object, which contains the type of viewport currently selected in the editor along with its width range.
   */
  function getCurrentViewport(token?: string): Promise<ViewportInfo> {
    return getAPI().then((api) =>
      api.editor.info.getCurrentViewport(appData, token),
    )
  }

  return {
    getDashboardUrl,
    getBusinessManagerUrl,
    getAscendUpgradeUrl,
    getEditorSessionId,
    getEditorReadyTimestamp,
    getEditorMode,
    getEditorReferrer,
    getConsentPolicy,
    getCurrentViewport,
  }
}
