import {getAPI} from '../privates/editorAPI'

export default function (appData) {
  /**
   * @doc canvasOverlay
   * @note `Classic Editor` `Editor X`
   * @description
   * > **Note**: This is an experimental API for editor add-ons.
   * This method adds an iframe overlay to the editor canvas.
   * An app can have only one active overlay. If an overlay already exists, an error will be thrown.
   * @example
   * editorSDK.editor.canvasOverlay.add('token', {url: 'https://artifact.path/canvasOverlay'})
   * @param token - app token - not in use.
   * @param options -
   * - `url`: The URL to load in the canvas overlay container.
   * @returns A promise that resolves to undefined when the overlay is added.
   */
  function add(
    token: string,
    options: {
      url: string
    },
  ): Promise<void> {
    return getAPI().then((api) =>
      api.editor.canvasOverlay.add(appData, options.url),
    )
  }

  /**
   * @doc canvasOverlay
   * @note `Classic Editor` `Editor X`
   * @description
   * > **Note**: This is an experimental API for editor add-ons.
   * This method removes an iframe overlay from the editor canvas.
   * If no canvas overlay was added for the app, an error will be thrown.
   * @example
   * editorSDK.editor.canvasOverlay.remove('token')
   * @param token - app token - not in use.
   * @returns A promise that resolves to undefined when the overlay is removed.
   */
  function remove(): Promise<void> {
    return getAPI().then((api) => api.editor.canvasOverlay.remove(appData))
  }

  return {
    add,
    remove,
  }
}
