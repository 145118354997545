import {PageRef, PageData} from '@wix/editor-platform-sdk-types'
import apiWrapper from '../../privates/apiWrapper'

export default function (appData) {
  const compOperationType = apiWrapper.OPERATION_TYPES.COMP

  /**
   * @doc PageData
   * @description Update data of page / popup page
   * @example await editorSDK.document.pages.data.update('token', {pageRef : {id: 'oy3rh', type: 'DESKTOP'}, data: {type: 'Page', id: 'mock', title: 'title'}})
   * @param token - app token - not in use
   * @param options -
   * - `pageRef`: Page ref
   * - `data`: Partial data to update
   * @returns -  A promise that is resolved when the data is updated
   */
  function update(
    token: string,
    options: {pageRef: PageRef; data: Partial<PageData>},
  ): Promise<void> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: options.pageRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) => api.document.pages.data.update(appData, token, options),
    )
  }

  /**
   * @doc PageData
   * @description Gets basic data of page / popup page
   * @example const pageProperties = await editorSDK.document.pages.data.get('token', {pageRef : {id: 'oy3rh', type: 'DESKTOP'}})
   * @param token - app token - not in use
   * @param options -
   * - `pageRef`: Page ref to get its data
   * @returns -  A promise with the page data
   */
  function get(token: string, options: {pageRef: PageRef}): Promise<PageData> {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: options.pageRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) => api.document.pages.data.get(appData, token, options),
    )
  }

  /**
   * @doc PageData
   * @description Gets page data of all site pages
   * @example const sitePages = await editorSDK.document.pages.data.getAll('token')
   * @param token - app token - not in use
   * @returns array of pageData objects
   */
  function getAll(token: string): Promise<PageData[]> {
    return apiWrapper.dsGetter(
      {
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      (api) => api.document.pages.data.getAll(appData, token),
    )
  }

  return {
    update,
    get,
    getAll,
  }
}
