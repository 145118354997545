import {getAPI} from '../privates/editorAPI'
import {Link} from '@wix/editor-platform-sdk-types'

export default function (appData) {
  /**
   * @doc editorUtils
   * @note `Classic Editor` `Editor X`
   * @param token - app token - not in use
   * @param options -
   *  - link: The Link object
   * @returns A promise with a string representation of the link object.
   */
  function getLinkAsString(token, options: {link: Link}): Promise<string> {
    return getAPI().then((api) => {
      return api.editor.utils.getLinkAsString(appData, token, options)
    })
  }

  /**
   * @doc editorUtils
   * @note `Classic Editor` `Editor X`
   * @description Retrieves a CSRF token used for API calls.
   * @example const csrfToken = await editorSDK.editor.utils.getCsrfToken(token);
   * @param token - app token - not in use
   * @returns A promise that is resolved with a CSRF token
   */
  async function getCsrfToken(token?: any): Promise<string> {
    const api = await getAPI()
    return api.editor.utils.getCsrfToken()
  }

  return {
    getLinkAsString,
    getCsrfToken,
  }
}
