import {Feature, FeatureWithNamed} from '@wix/deeplink-core'

import {getAPI} from '../privates/editorAPI'

export default function Deeplink(appData) {
  /**
   * @doc Deeplink
   * @note `Classic Editor` `Editor X`
   * @example
   * const editorCanShowMobileViewport = await editorSDK.editor.deeplink.check(token, { type: 'viewport', params: ['mobile'] });
   * @param {string} token - app token, not in use.
   * @param {Feature} feature - The Deeplink feature from [this list](https://github.com/wix-private/editor-platform/blob/master/packages/deeplink-core/docs/features.md).
   * @description Checks if a *Deeplink* feature is available, enabling your app to show that feature. [Learn more](../articles/deeplinks-dev.md).
   * @returns A promise that is resolved with a Boolean value, *true* if the specified Deeplink feature is available, otherwise, *false*.
   */
  function check(
    token: string,
    feature: Feature | FeatureWithNamed,
  ): Promise<boolean> {
    if (arguments.length < 2) {
      // caller forgot the first (unused, but kept for consistency) `token` argument
      throw new Error(
        'this function accepts at least two arguments. did you forget about "token"?',
      )
    }
    return getAPI().then((api) => {
      return api.editor.deeplink.check(appData, token, feature)
    })
  }

  /**
   * @doc Deeplink
   * @note `Classic Editor` `Editor X`
   * @example
   * await editorSDK.editor.deeplink.show(token, { type: 'viewport', params: ['mobile'] });
   * @param {string} token - app token, not in use.
   * @param {Feature} feature - The Deeplink feature from [this list](https://github.com/wix-private/editor-platform/blob/master/packages/deeplink-core/docs/features.md).
   * @description Show a *Deeplink* feature. [Learn more](../articles/deeplinks-dev.md).
   * @returns A promise that is resolved when the Editor has displayed the specified feature, or when the method determines that the feature isn't available.
   */
  function show(
    token: string,
    feature: Feature | FeatureWithNamed,
  ): Promise<void> {
    if (arguments.length < 2) {
      // caller forgot the first (unused, but kept for consistency) `token` argument
      throw new Error(
        'this function accepts at least two arguments. did you forget about "token"?',
      )
    }

    return getAPI().then((api) => {
      return api.editor.deeplink.show(appData, token, feature)
    })
  }

  return {
    check,
    show,
  }
}
