import {addEventListener} from './windowMessage'
import {setCommonConfig} from './commonConfigService'

export const MessageTypes = {
  PLATFORM_SDK_READY: 'PLATFORM_SDK_READY',
}

export const MessageIntents = {
  PLATFORM_PANEL: 'PLATFORM_PANEL',
}

let eventHandlers = []
let msgQueue = []
let startConfigurationEvent = null

export function initPostMessage() {
  eventHandlers = []
  msgQueue = []
  startConfigurationEvent = null
  addEventListener(handler)
}

function getTarget() {
  if (parent.postMessage) {
    return parent
  }
  if (parent.document.postMessage) {
    return parent.document
  }
}

export function sendMessage(data) {
  var target = getTarget()
  data.sourcePanel = window.name
  if (target && typeof target !== 'undefined') {
    target.postMessage(data, '*')
  }
}

export function addOnEventCallback(appData, cb) {
  if (msgQueue.length > 0) {
    while (msgQueue.length) {
      const {event} = msgQueue.shift() // First In, First Out
      cb(event)
    }
  } else if (startConfigurationEvent) {
    cb(startConfigurationEvent)
  }

  eventHandlers.push(cb)
}

function handler(msg) {
  if (!msg || !msg.data) {
    return
  }

  const {event, intent} = msg.data
  switch (intent) {
    case 'PLATFORM_ON_EVENT':
      if (event.eventType === 'startConfiguration') {
        setCommonConfig(event.eventPayload.commonConfig)
        startConfigurationEvent = event
      }

      if (eventHandlers.length > 0) {
        eventHandlers.forEach((cb) => cb(event))
      } else {
        msgQueue.push({event, intent}) // First In, First Out
      }

      break
  }
}
