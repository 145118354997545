import Colors from './colors'
import Fonts from './fonts'
import Styles from './styles'

export default function (appData) {
  return {
    styles: Styles(appData),
    colors: Colors(appData),
    fonts: Fonts(appData),
  }
}
