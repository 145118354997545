import {getAPI} from '../../privates/editorAPI'

export default function (appData) {
  /**
   * @doc Site.UserPreferences
   * @note `Classic Editor` `Editor X`
   * @example
   * editorSDK.editor.userPreferences.site.set(token, {key: 'value'});
   * @param {string} token - app token, not in use
   * @param preferences - An object of the user preferences to update.
   * @description Sets the specified user preferences for the site.
   * @returns - A promise that is resolved when preferences are set.
   */
  function set(
    token,
    preferences: {
      [index: string]: any
    },
  ) {
    return getAPI().then((api) => {
      return api.editor.userPreferences.site.set(appData, token, preferences)
    })
  }

  /**
   * @doc Site.UserPreferences
   * @note `Classic Editor` `Editor X`
   * @example
   * const preferences = await editorSDK.editor.userPreferences.site.get(token, ['editorAppId_viewtools.developerModeEnabled']);
   * @param {string} token - app token, not in use
   * @param preferences - An array or object of all the user preferences to get.
   * @description Returns the current value for the specified user preferences.
   * @returns A promise to a map object of the specified user preferences and their values.
   */
  function get(
    token,
    preferences:
      | string[]
      | {
          [index: string]: any
        },
  ): Promise<{[index: string]: any}> {
    return getAPI().then((api) => {
      return api.editor.userPreferences.site.get(appData, token, preferences)
    })
  }

  return {
    set,
    get,
  }
}
