import Application from './document/application/application'
import Controllers from './document/controllers'
import History from './document/history'
import Components from './document/components/components'
import Fonts from './document/fonts/fonts'
import Info from './document/info'
import Language from './document/language'
import Menu from './document/menu'
import Pages from './document/pages/pages'
import PagesGroup from './document/pagesGroup/pagesGroup'
import Routers from './document/routers/routers'
import SiteSegments from './document/siteSegments'
import SiteMembers from './document/siteMembers'
import Theme from './document/theme/theme'
import TPA from './document/tpa/tpa'
import Vfs from './document/vfs'
import Mobile from './document/mobile/mobile'
import ResponsiveLayout from './document/responsiveLayout'
import Errors from './document/errors/errors'
import Wixapps from './document/wixapps'
import Accessibility from './document/accessibility'
import Account from './document/account'
import Environment from './document/environment'
import Transactions from './document/transactions/transactions'
import Permissions from './document/permissions/permissions'
import {
  SDKDefaultContext,
  SDKContext,
  AppData,
} from '@wix/editor-platform-sdk-types'
import {deprecatedMethod} from '../utils/utils'

export default function <Context extends SDKContext = SDKDefaultContext>(
  appData: AppData,
) {
  /**
   * @doc Document
   * Apps should not explicitly save the site. The logic for saving the site is managed exclusively by the editors and the Editor Platform.
   * @returns A promise that is resolved immediately without save.
   */
  function save(): Promise<any> {
    deprecatedMethod('document.save()')
    return Promise.resolve()
  }

  return {
    save,
    application: Application<Context>(appData),
    controllers: Controllers<Context>(appData),
    history: History(appData),
    info: Info<Context>(appData),
    language: Language(appData),
    menu: Menu<Context>(appData),
    pages: Pages<Context>(appData),
    pagesGroup: PagesGroup<Context>(appData),
    siteSegments: SiteSegments(appData),
    siteMembers: SiteMembers(appData),
    vfs: Vfs(appData),
    components: Components<Context>(appData),
    fonts: Fonts(appData),
    routers: Routers<Context>(appData),
    theme: Theme(appData),
    tpa: TPA<Context>(appData),
    mobile: Mobile(appData),
    responsiveLayout: ResponsiveLayout(appData),
    errors: Errors(appData),
    wixapps: Wixapps(appData),
    accessibility: Accessibility(appData),
    account: Account(appData),
    environment: Environment(appData),
    transactions: Transactions(appData),
    permissions: Permissions(appData),
  }
}
