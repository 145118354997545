import apiWrapper from '../../privates/apiWrapper'
import {getAPI} from '../../privates/editorAPI'
import {ComponentRef} from '@wix/editor-platform-sdk-types'

export default function (appData) {
  /**
   * @description Moves a component to a specific index in the children array under its container. Each component in the container is identifiable by its index.
   * @doc Arrangement
   * @example
   * const slideshowRef = await editorSDK.document.components.getById('token', {id: 'comp-jhq8y9hp'});
   * const dropIdx = 2;
   * editorSDK.components.arrangement.moveToIndex('token', {componentRef: slideshowRef, index: dropIdx});
   * @param token - app token - not in use
   * @param options -
   * - componentRef: Pointer to the component to move.
   * - index: The index to move the component to.
   @returns a promise that is resolved when the changes applied to the document.
   */
  function moveToIndex(
    token,
    options: {componentRef: ComponentRef; index: number},
  ): Promise<null> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: apiWrapper.OPERATION_TYPES.COMP,
      },
      (api) =>
        api.document.components.arrangement.moveToIndex(
          appData,
          token,
          options,
        ),
    )
  }

  /**
   * @doc Arrangement
   * @description Gets the component's index number within the parent container. Each component in the container is identifiable by its index.
   * @example const componentIndex = await editorSDK.document.components.arrangement.getIndex(token, { componentRef })
   * @param token - app token - not in use
   * @param options -
   * @param options.componentRef - The reference to the component whose index you want to get.
   * @returns A promise with the index number of the component within the parent container.
   */
  function getIndex(
    token: string,
    options: {componentRef: ComponentRef},
  ): Promise<number> {
    return getAPI().then((api) =>
      api.document.components.arrangement.getIndex(appData, token, options),
    )
  }

  return {
    getIndex,
    moveToIndex,
  }
}
