import {
  MessageIntents,
  MessageTypes,
  initPostMessage,
  sendMessage,
} from '../privates/postMessage'
import {initRPC} from './pmRpcUtils'

export function initEditor() {
  initPostMessage()
  return initRPC('editorAPI', parent).then(() => {
    sendMessage({
      type: MessageTypes.PLATFORM_SDK_READY,
      intent: MessageIntents.PLATFORM_PANEL,
    })
  })
}
