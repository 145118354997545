import * as rpc from 'pm-rpc'
import {getApiName, API_TYPES} from './utils'
import {
  getDefaultAppEditorApi,
  getFullAppEditorApi,
} from './appEditorApiDefaults'
import {initRPC} from './pmRpcUtils'

let workerReloadManifestMethod = () => Promise.resolve()

const initWorker = (reloadManifestMethod) => {
  workerReloadManifestMethod = reloadManifestMethod

  return initRPC('editor')
}

const appsAPI = {}

const {PUBLIC, PRIVATE, EDITOR} = API_TYPES
const apiTypes = [PUBLIC, PRIVATE, EDITOR]

const defaultApis = {
  [PUBLIC]: {},
  [PRIVATE]: {},
  [EDITOR]: getDefaultAppEditorApi(),
}

const wrapApis = {
  [PUBLIC]: (api) => api,
  [PRIVATE]: (api) => api,
  [EDITOR]: getFullAppEditorApi,
}

const setAppExportedApi = (
  appDefinitionId,
  api,
  apiType,
  handleActionFn,
  boundedSdk,
) => {
  const wrappedApi =
    wrapApis[apiType](api, appDefinitionId, handleActionFn, boundedSdk) ||
    defaultApis[apiType]
  appsAPI[appDefinitionId] = appsAPI[appDefinitionId] || {}
  appsAPI[appDefinitionId][apiType] = wrappedApi
  const apiName = getApiName(appDefinitionId, apiType)
  rpc.api.set(apiName, wrappedApi)
  return apiName
}

const unsetAppExportedApis = (appDefinitionId) => {
  delete appsAPI[appDefinitionId]
  apiTypes.forEach((apiType) => {
    const apiName = getApiName(appDefinitionId, apiType)
    rpc.api.unset(apiName)
  })
}

const setAppExportedApis = (
  appDefinitionId,
  apis = {},
  handleActionFn,
  boundedSdk,
) => {
  appsAPI[appDefinitionId] = {}
  const apisNames = {}
  apiTypes.forEach((apiType) => {
    apisNames[apiType] = setAppExportedApi(
      appDefinitionId,
      apis[apiType] || {},
      apiType,
      handleActionFn,
      boundedSdk,
    )
  })
  return apisNames
}

const getAppExportedApis = (appDefinitionId) => appsAPI[appDefinitionId]

const reloadAppManifest = (appData) => workerReloadManifestMethod(appData)

export {
  initWorker,
  reloadAppManifest,
  setAppExportedApi,
  setAppExportedApis,
  getAppExportedApis,
  unsetAppExportedApis,
}
