import {ComponentDefinition} from './types'
import {PageDefinition} from '@wix/editor-platform-sdk-types'
import {getAPI} from '../../privates/editorAPI'

const AppController = 'platform.components.AppController'
type AppControllerType = typeof AppController

const AppWidget = 'platform.components.AppWidget'
type AppWidgetType = typeof AppWidget

const Page = 'mobile.core.components.Page'
type PageType = typeof Page

type ComponentType = AppControllerType | AppWidgetType | PageType | string

type AppControllerOptions = {
  appDefinitionId: string
  controllerType: string
  name?: string
  settings?: Record<string, unknown>
  layout?: ComponentDefinition['layout']
}
type AppWidgetOptions = {
  appDefinitionId: string
  controllerType: string
  name?: string
  layout?: ComponentDefinition['layout']
  children: [ComponentDefinition]
}
type PageOptions = {
  data: PageDefinition['data']
  components?: ComponentDefinition[]
  responsiveSectionOptions?: ResponsiveSectionOptions
}

type ResponsiveSectionOptions = {
  style?: ComponentDefinition['style']
  layoutResponsive?: ComponentDefinition['layoutResponsive']
}

type ComponentTypeOptions<T extends ComponentType> = T extends AppControllerType
  ? AppControllerOptions
  : T extends AppWidgetType
    ? AppWidgetOptions
    : T extends PageType
      ? PageOptions
      : {}

type ComponentTypeDefinition<T extends ComponentType> = T extends PageType
  ? PageDefinition
  : ComponentDefinition

export default function (appData) {
  async function createAppControllerDefinition(
    api,
    token,
    options: AppControllerOptions,
  ): Promise<ComponentDefinition> {
    const defaultStructure =
      await api.document.components.buildDefaultComponentStructure(
        appData,
        token,
        {componentType: AppController},
      )
    defaultStructure.data = {
      ...defaultStructure.data,
      controllerType: options.controllerType,
      applicationId: options.appDefinitionId,
      name: options.name,
      settings: JSON.stringify(options.settings || {}),
    }

    defaultStructure.layout = options.layout || defaultStructure.layout

    return defaultStructure
  }

  async function createAppWidgetDefinition(
    api,
    token,
    options: AppWidgetOptions,
  ): Promise<ComponentDefinition> {
    const defaultStructure =
      await api.document.components.buildDefaultComponentStructure(
        appData,
        token,
        {componentType: AppWidget},
      )
    defaultStructure.data = {
      ...defaultStructure.data,
      controllerType: options.controllerType,
      name: options.name,
      applicationId: options.appDefinitionId,
    }

    defaultStructure.components = options.children

    defaultStructure.layout = options.layout || defaultStructure.layout

    return defaultStructure
  }

  async function createPageDefinition(
    api,
    token,
    options: PageOptions,
  ): Promise<PageDefinition> {
    const defaultStructure =
      await api.document.components.buildDefaultComponentStructure(
        appData,
        token,
        {componentType: Page},
      )
    defaultStructure.data = {
      ...defaultStructure.data,
      ...options.data,
    }

    const pageComponents = options.responsiveSectionOptions
      ? [
          createResponsiveSectionDefinition(api, token, {
            ...options.responsiveSectionOptions,
            components: options.components,
          }),
        ]
      : options.components

    defaultStructure.components = pageComponents || defaultStructure.components

    return defaultStructure
  }

  function createResponsiveSectionDefinition(
    api,
    token,
    options: ResponsiveSectionOptions & {components?: ComponentDefinition[]},
  ): ComponentDefinition {
    const defaultStructure =
      api.document.components.buildDefaultComponentStructure(appData, token, {
        componentType: 'responsive.components.Section',
      })

    defaultStructure.style = options.style || defaultStructure.style
    defaultStructure.layoutResponsive =
      options.layoutResponsive || defaultStructure.layoutResponsive

    defaultStructure.components =
      options.components || defaultStructure.components

    return defaultStructure
  }

  const componentDefinitionFactoryMap: Readonly<
    Record<
      ComponentType,
      (
        api,
        token,
        options: ComponentTypeOptions<ComponentType>,
      ) => Promise<ComponentDefinition | PageDefinition>
    >
  > = {
    [AppController]: createAppControllerDefinition,
    [AppWidget]: createAppWidgetDefinition,
    [Page]: createPageDefinition,
  }

  /**
   * @doc Components
   * @description Creates a basic component definition for the provided component type.
   * @example
   * const controllerDefinition = await editorSDK.document.components.createDefinition('token', {
   *   componentType: 'platform.components.AppController',
   *   appDefinitionId: '02edb63a-e857-4f1d-bae8-7cd1fcb34707',
   *   controllerType: 'search-controller',
   *   name: 'Site Search',
   * });
   *
   * editorSDK.document.components.add('token', {
   *   componentDefinition: controllerDefinition,
   *   pageRef
   * });
   * @param token - app token, not in use
   * @param options - Settings for the component definition of the provided component type. Available options properties depend on the component type.
   *  - componentType: The type of component for which to create the definition.
   *  - appDefinitionId: The application definition ID ('platform.components.AppController' and 'platform.components.AppWidget' component types only).
   *  - controllerType: The type of the defined controller ('platform.components.AppController' and 'platform.components.AppWidget' component types only).
   *  - name: The display name of the defined controller ('platform.components.AppController' and 'platform.components.AppWidget' component types only).
   *  - layout: The layout for the defined controller ('platform.components.AppController' and 'platform.components.AppWidget' component types only).
   *  - settings: Settings of the defined controller ('platform.components.AppController' component type only).
   *  - childComponent: The definition of the child component to be rendered inside the App Widget ('platform.components.AppWidget' component type only).
   *  - data: The page data of the defined page ('mobile.core.components.Page' component type only).
   *  - components: The definition of the child components to be rendered inside the page or its responsive section component (if 'withResponsiveSection' boolean flag is 'true') ('mobile.core.components.Page' component type only).
   *  - responsiveSectionOptions: Settings for the defined responsive section component in the page definition, which is created if settings are provided ('mobile.core.components.Page' component type only, Editor X only).
   *  - responsiveSectionOptions.style: The style parameters of the responsive section component ('mobile.core.components.Page' component type only, Editor X only).
   *  - responsiveSectionOptions.layoutResponsive: The responsive layout of the responsive section component ('mobile.core.components.Page' component type only, Editor X only).
   * @returns A component definition for the provided component type.
   */
  function createDefinition<T extends ComponentType>(
    token,
    options: {componentType: T} & ComponentTypeOptions<T>,
  ): Promise<ComponentTypeDefinition<T>> {
    return getAPI().then((api) => {
      const componentDefinitionFactory =
        componentDefinitionFactoryMap[options.componentType]

      if (!componentDefinitionFactory) {
        return api.document.components.buildDefaultComponentStructure(
          appData,
          token,
          {componentType: options.componentType},
        )
      }

      return componentDefinitionFactory(api, token, options)
    })
  }

  return {createDefinition}
}
