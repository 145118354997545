import {ComponentRef, LanguageCode} from '@wix/editor-platform-sdk-types'
import apiWrapper from '../../privates/apiWrapper'

export default function (appData) {
  const compOperationType = apiWrapper.OPERATION_TYPES.COMP

  /**
   * @description Get the data of the given componentRef.
   * @doc Data
   * @example
   * const componentRef = await editorSDK.document.components.getById('token', {id: 'comp-jhq8y9hp'});
   * const data = await editorSDK.components.data.get('token', {componentRef: componentRef});
   * @param token - app token - not in use
   * @param options -
   * - `componentRef`: the component ref.
   * @returns a promise that is resolved with the data of the given componentRef.
   */
  function get(token, options: {componentRef: ComponentRef}): Promise<object> {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) => api.document.components.data.get(appData, token, options),
    )
  }

  /**
   * @description Update the data of the given componentRef.
   * @doc Data
   * @example
     const componentRef = await editorSDK.document.components.getById('token', {id: 'comp-jhq8y9hp'});
     await editorSDK.components.data.update('token', {componentRef: componentRef, data: {label: 'new label'}});
   * @param token - app token - not in use
   * @param options
   * - `componentRef`: the component ref.
   * - `data`: an object containing the data fields to update.
   * @returns a promise that is resolved when the changes has applied to the document.
   */
  function update(
    token,
    options: {componentRef: ComponentRef; data: object},
  ): Promise<void> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) => api.document.components.data.update(appData, token, options),
    )
  }

  /**
   * @description Update the data of the given componentRef in a language.
   * @doc Data
   * @example
     const componentRef = await editorSDK.document.components.getById('token', {id: 'comp-jhq8y9hp'});
     await editorSDK.components.data.updateInLanguage('token', {componentRef: componentRef, data: {label: 'new label'}, languageCode: 'he'});
   * @param token - app token - not in use
   * @param options
   * - `componentRef`: the component ref.
   * - `data`: an object containing the data fields to update.
   * - `languageCode`: the code of the language that is being used in the update.
   * @returns a promise that is resolved when the changes has applied to the document.
   */
  function updateInLanguage(
    token,
    options: {
      componentRef: ComponentRef
      data: object
      languageCode: LanguageCode
    },
  ): Promise<void> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) =>
        api.document.components.data.updateInLanguage(appData, token, options),
    )
  }

  return {
    get,
    update,
    updateInLanguage,
  }
}
