import {getAPI} from '../privates/editorAPI'

export default function (appData) {
  /**
   * @doc Accessibility
   * @description Gets the skipToContent flag value. When set to *true*, the **Skip to Content** button appears in the
   * Accessibility Wizard.
   * @example const skipToContentFlag = await editorSDK.document.accessibility.getSkipToContent('token')
   * @param token - app token - not in use
   * @returns A promise that is resolved with the Boolean value of the flag, *true* if the flag is set.
   */
  function getSkipToContent(token): Promise<boolean | null> {
    return getAPI().then((api) => {
      return api.document.accessibility.getSkipToContent(appData, token)
    })
  }

  /**
   * @doc Accessibility
   * @description Sets the skipToContent flag value. When set to *true*, the **Skip to Content** button appears in the
   * Accessibility Wizard.
   * @example await editorSDK.document.accessibility.setSkipToContent('token', true)
   * @param token - app token - not in use
   * @param enable {Boolean}
   * @returns A promise` that is resolved once the flag is set.
   */
  function setSkipToContent(token, enable): Promise<void> {
    return getAPI().then((api) => {
      return api.document.accessibility.setSkipToContent(appData, token, enable)
    })
  }

  /**
   * @doc Accessibility
   * @description Gets the visualFocus flag value. This is accessibility functionality to enhance user orientation on the site,
   * by making obvious which element is in focus.
   * @example const visualFocusEnabled = await editorSDK.document.accessibility.getVisualFocus('token')
   * @param token - app token - not in use
   * @returns A promise that is resolved with the Boolean value of the flag, *true* if the flag is set.
   */
  function getVisualFocus(token): Promise<boolean | null> {
    return getAPI().then((api) => {
      return api.document.accessibility.getVisualFocus(appData, token)
    })
  }

  /**
   * @doc Accessibility
   * @description Sets the visualFocus flag value.
   * @example await editorSDK.document.accessibility.setVisualFocus('token', true)
   * @param token - app token - not in use
   * @param enable {Boolean}
   * @returns A promise` that is resolved once the flag is set.
   */
  function setVisualFocus(token, enable): Promise<void> {
    return getAPI().then((api) => {
      return api.document.accessibility.setVisualFocus(appData, token, enable)
    })
  }

  /**
   * @doc Accessibility
   * @description Gets the autoDomReorder flag value. This is accessibility functionality that automatically optimizes DOM order
   * for tabbing through elements on the site.
   * @example const autoDomReorderEnabled = await editorSDK.document.accessibility.getAutoDomReorder('token')
   * @param token - app token - not in use
   * @returns A promise that is resolved with a Boolean value, *true* if the flag is set.
   */
  function getAutoDomReorder(token): Promise<boolean | null> {
    return getAPI().then((api) => {
      return api.document.accessibility.getAutoDomReorder(appData, token)
    })
  }

  /**
   * @doc Accessibility
   * @description Sets autoDomReorder flag value. This is accessibility functionality that automatically optimizes DOM order
   * for tabbing through elements on the site.
   * @example await editorSDK.document.accessibility.setAutoDomReorder('token', true)
   * @param token - app token - not in use
   * @param enable {Boolean}
   * @returns A promise that is resolved once the flag is set.
   */
  function setAutoDomReorder(token, enable): Promise<void> {
    return getAPI().then((api) => {
      return api.document.accessibility.setAutoDomReorder(
        appData,
        token,
        enable,
      )
    })
  }

  return {
    getSkipToContent,
    setSkipToContent,
    getVisualFocus,
    setVisualFocus,
    getAutoDomReorder,
    setAutoDomReorder,
  }
}
