import {AppData, ComponentRef} from '@wix/editor-platform-sdk-types'
import apiWrapper from '../../privates/apiWrapper'

export default function (appData) {
  const compOperationType = apiWrapper.OPERATION_TYPES.COMP

  /**
   * @doc Components
   * @description Returns the component's nickname.
   * @example
   * const compStructure = await editorSDK.document.components.code.getNickname(token, {componentRef});
   * @param {string} token - app token, not in use
   * @param options -
   *  - componentRef: A reference to the component.
   * @returns A promise that is resolved with the component's nickname.
   */
  function getNickname(
    token,
    options: {componentRef: ComponentRef},
  ): Promise<string> {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) =>
        api.document.components.code.getNickname(appData, token, options),
    )
  }

  async function validateAppContext(
    appData: AppData,
    token: string,
    api: any,
    componentRef: ComponentRef,
  ) {
    const [componentStructure] = await api.document.components.get(
      appData,
      token,
      {componentRefs: componentRef, properties: ['data']},
    )

    const compAppDefId = componentStructure?.data?.appDefinitionId
    const compApplicationId = componentStructure?.data?.applicationId

    if (
      appData.appDefinitionId &&
      appData.appDefinitionId !== compAppDefId &&
      appData.appDefinitionId !== compApplicationId
    ) {
      throw new Error(
        `Can not call for applicationId: ${appData.applicationId} because componentRef belongs to applicationId: ${compApplicationId} and appDefinitionId: ${compAppDefId}`,
      )
    }
  }

  /**
   * @doc Components
   * @description Sets the component's nickname.
   * @example
   * await editorSDK.document.components.code.setNickname(
   *   'token',
   *   {
   *     componentRef,
   *     nickname: 'newNickname',
   *   }
   * )
   * @param {string} token - app token, not in use
   * @param options -
   *  - componentRef: A reference to the component.
   *  - nickname: A new nickname value to update.
   * @returns A promise that is resolved when the changes are applied.
   */
  function setNickname(
    token,
    options: {componentRef: ComponentRef; nickname: string},
  ): Promise<void> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      async (api) => {
        await validateAppContext(appData, token, api, options.componentRef)
        return api.document.components.code.setNickname(appData, token, options)
      },
    )
  }

  return {
    getNickname,
    setNickname,
  }
}
