import {FontOption} from '@wix/editor-platform-sdk-types'
import apiWrapper from '../../privates/apiWrapper'

export default function (appData) {
  /**
   * @doc Fonts
   * @description Returns the site font options, organized by language, for the fonts drop-down menu.
   * @example const fonts = await editorSDK.document.fonts.getFontsOptions('token')
   * @param token - app token - not in use
   * @returns Promise that returns the font options of the site. the promise contains array of objects containing:
   * - lang: a string indicating the language.
   * - fonts: an array of font objects.
   */
  function getFontsOptions(token: string): Promise<FontOption[]> {
    return apiWrapper.dsGetter(
      {waitingType: apiWrapper.WAITING_TYPES.NONE},
      (api) => api.document.fonts.getFontsOptions(appData, token),
    )
  }

  return {
    getFontsOptions,
  }
}
