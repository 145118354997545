import {SettingsEditorSDKAdapter} from '@wix/editor-platform-sdk-types'

interface EditorSdkAdapterOpts {
  editorSdkInstance: EditorSdk
  signedInstance: string
}

interface EditorSdk {
  getExternalId(): string
  setExternalId(externalId: string): void
  getInstanceId(): string
  getAppDefId(): string
  triggerUpdate(message: any): void
}

export class EditorSdkAdapter implements SettingsEditorSDKAdapter {
  private editorSdk: EditorSdk
  signedInstance: string

  constructor({editorSdkInstance, signedInstance}: EditorSdkAdapterOpts) {
    this.editorSdk = editorSdkInstance
    this.signedInstance = signedInstance
  }

  getAppDefId(): string {
    return this.editorSdk.getAppDefId()
  }

  getInstanceId(): string {
    return this.editorSdk.getInstanceId()
  }

  async getExternalId(): Promise<string> {
    return this.editorSdk.getExternalId()
  }

  async setExternalId(externalId: string): Promise<void> {
    await this.editorSdk.setExternalId(externalId)
  }

  triggerSettingsUpdated(data: any, scope: any): void {
    const message = {
      scope,
      payload: data,
      source: 'app-settings',
    }
    this.editorSdk.triggerUpdate(message)
  }

  onSettingsUpdated(/*scope: Scope, cb: (k: object) => void*/): void {
    //
  }
}
