const API_TYPES = {
  PUBLIC: 'public',
  PRIVATE: 'private',
  EDITOR: 'editor',
}

const isWebWorker = () =>
  typeof WorkerGlobalScope !== 'undefined' && self instanceof WorkerGlobalScope

const isNodeWorker = () => {
  return typeof process !== 'undefined'
}

const isWorker = () => isWebWorker() || isNodeWorker()

const isMainFrame = () =>
  typeof window !== 'undefined' && window === window.parent

const getWindow = () => window

const getApiName = (appDefinitionId, apiType) =>
  `platform_${apiType}_${appDefinitionId}`

export {
  API_TYPES,
  isWebWorker,
  isNodeWorker,
  isWorker,
  isMainFrame,
  getWindow,
  getApiName,
}
