import {ComponentRef} from '@wix/editor-platform-sdk-types'
import type {
  GridLayout,
  addGridContainerOptions,
  ComponentRefOptions,
  updateGapsOptions,
  updateLayoutOptions,
  addRowOrColumnOptions,
  removeRowOrColumnOptions,
  GridItemPosition,
  updateChildPositionsOptions,
  addChildOptions,
} from '@wix/editor-platform-sdk-types'
import {getAPI} from '../../privates/editorAPI'

export default function (appData) {
  /**
   * @doc Grid
   * @note `Editor X`
   * @example
   * const gridContainer = await editorSDK.responsive.grid.addGridContainer(token, {
   *   containerRef,
   *   layout: {
   *     rows: [{width: 55, height: 55}],
   *     columns: [{width: 55, height: 55}],
   *     rowGap: {type: 'auto'},
   *     columnGap: {type: 'auto'}
   *   }
   * });
   * @param token - app token - not in use
   * @param options -
   * @param options.containerRef - parent container for the grid container.
   * @param options.layout - grid layout for the default breakpoint.
   * @description Adds a grid container to the specified parent container.
   * @returns A promise that resolves to the component ref of the added grid container.
   */
  function addGridContainer(
    token: string,
    options: addGridContainerOptions,
  ): Promise<ComponentRef> {
    return getAPI().then((api) =>
      api.responsive.grid.addGridContainer(appData, token, options),
    )
  }

  /**
   * @doc Grid
   * @note `Editor X`
   * @example
   * const layouts = await editorSDK.responsive.grid.getLayouts(token, {
   *   componentRef,
   * });
   * @param token - app token - not in use
   * @param options -
   * @param options.componentRef - component reference for the grid container.
   * @description Gets a list of grid layouts.
   * Returns the layout for the default breakpoint, and any layouts for breakpoints
   * where a user made changes in the grid layout.
   * @returns A promise that resolves to the list of layouts.
   */
  function getLayouts(
    token: string,
    options: ComponentRefOptions,
  ): Promise<GridLayout[]> {
    return getAPI().then((api) =>
      api.responsive.grid.getLayouts(appData, token, options),
    )
  }

  /**
   * @doc Grid
   * @note `Editor X`
   * @example
   * await editorSDK.responsive.grid.updateLayout(token, {
   *   componentRef: containerRef,
   *   layout: {
   *     rows: [{ height: 55, width: 55 }],
   *     columns: [{ height: 55, width: 55 }],
   *     breakpointId: 'someid',
   *   }
   * });
   * @param token - app token - not in use
   * @param options -
   * @param options.componentRef - component reference for the grid container.
   * @param options.layout - grid layout for specified breakpoint.
   * @description Updates the layout for a specific breakpoint on the specified container.
   * @returns A promise that resolves once the operation is done.
   */
  function updateLayout(
    token: string,
    options: updateLayoutOptions,
  ): Promise<void> {
    return getAPI().then((api) =>
      api.responsive.grid.updateLayout(appData, token, options),
    )
  }

  /**
   * @doc Grid
   * @note `Editor X`
   * @example
   * await editorSDK.responsive.grid.updateGaps(token, {
   *   componentRef: containerRef,
   *   rowGap: { type: 'px', value: 10 },
   *   columnGap: { type: 'fr', value: 1 },
   *   breakpointId: 'someid',
   * });
   * @param token - app token - not in use
   * @param options -
   * @param options.componentRef - component reference for the grid container.
   * @param options.rowGap - defines the gap size between all grid rows.
   * @param options.columnGap - defines the gap size between all grid columns.
   * @param options.breakpointId - the breakpoint whose gaps you are updating.
   * @description Updates row and column gap sizes for the grid in specified breakpoint.
   * @returns A promise that resolves once the operation is done.
   */
  function updateGaps(
    token: string,
    options: updateGapsOptions,
  ): Promise<void> {
    return getAPI().then((api) =>
      api.responsive.grid.updateGaps(appData, token, options),
    )
  }

  /**
   * @doc Grid
   * @note `Editor X`
   * @example
   * await editorSDK.responsive.grid.addRow(token, {
   *   componentRef: containerRef,
   *   index: 1,
   *   size: { height: 55, width: 55 },
   *   breakpointId: 'someid',
   * });
   * @param token - app token - not in use
   * @param options -
   * @param options.componentRef - component reference for the grid container.
   * @param options.index - index (starting from 0) of position to insert the row.
   * @param options.size - height of the row.
   * @description Inserts new row into the grid at the specified position.
   * @returns A promise that resolves once the operation is done.
   */
  function addRow(
    token: string,
    options: addRowOrColumnOptions,
  ): Promise<void> {
    return getAPI().then((api) =>
      api.responsive.grid.addRow(appData, token, options),
    )
  }

  /**
   * @doc Grid
   * @note `Editor X`
   * @example
   * await editorSDK.responsive.grid.addColumn(token, {
   *   componentRef: containerRef,
   *   index: 1,
   *   size: { height: 55, width: 55 },
   *   breakpointId: 'someid',
   * });
   * @param token - app token - not in use
   * @param options -
   * @param options.componentRef - component reference for the grid container.
   * @param options.index - index (starting from 0) of position to insert the column.
   * @param options.size - width of the column.
   * @description Inserts new column into the grid at the specified position.
   * @returns A promise that resolves once the operation is done.
   */
  function addColumn(
    token: string,
    options: addRowOrColumnOptions,
  ): Promise<void> {
    return getAPI().then((api) =>
      api.responsive.grid.addColumn(appData, token, options),
    )
  }

  /**
   * @doc Grid
   * @note `Editor X`
   * @example
   * await editorSDK.responsive.grid.removeRow(token, {
   *   componentRef: containerRef,
   *   index: 1,
   *   breakpointId: 'someid',
   * });
   * @param token - app token - not in use
   * @param options -
   * @param options.componentRef - component reference for the grid container.
   * @param options.index - index (starting from 0) of the position of the row to remove
   * @description Removes the row at the specified position.
   * @returns A promise that resolves once the operation is done.
   */
  function removeRow(
    token: string,
    options: removeRowOrColumnOptions,
  ): Promise<void> {
    return getAPI().then((api) =>
      api.responsive.grid.removeRow(appData, token, options),
    )
  }

  /**
   * @doc Grid
   * @note `Editor X`
   * @example
   * await editorSDK.responsive.grid.removeColumn(token, {
   *   componentRef: containerRef,
   *   index: 1,
   *   breakpointId: 'someid',
   * });
   * @param token - app token - not in use
   * @param options -
   * @param options.componentRef - component reference for the grid container.
   * @param options.index - index (starting from 0) of the position of the column to remove.
   * @description Removes the column at the specified position.
   * @returns A promise that resolves once the operation is done.
   */
  function removeColumn(
    token: string,
    options: removeRowOrColumnOptions,
  ): Promise<void> {
    return getAPI().then((api) =>
      api.responsive.grid.removeColumn(appData, token, options),
    )
  }

  /**
   * @doc Grid
   * @note `Editor X`
   * @example
   * const positions = await editorSDK.responsive.grid.getChildPositions(token, {
   *   componentRef,
   * });
   * @param token - app token - not in use
   * @param options -
   * @param options.componentRef - component reference for the grid child component.
   * @description Gets positions (row start, row end, column start, column end) of a specified component in its parent grid container,
   * in all breakpoints where the position is specified.
   * @returns A promise that resolves to the list of item positions.
   */
  function getChildPositions(
    token: string,
    options: ComponentRefOptions,
  ): Promise<GridItemPosition[]> {
    return getAPI().then((api) =>
      api.responsive.grid.getChildPositions(appData, token, options),
    )
  }

  /**
   * @doc Grid
   * @note `Editor X`
   * @example
   * await editorSDK.responsive.grid.updateChildPositions(token, {
   *   componentRef,
   *   positions: [
   *     {
   *       gridArea: {
   *         rowStart: 1,
   *         rowEnd: 2,
   *         columnStart: 1,
   *         columnEnd: 3,
   *       },
   *       breakpointId: undefined,
   *     },
   *     {
   *       gridArea: {
   *         rowStart: 2,
   *         rowEnd: 3,
   *         columnStart: 1,
   *         columnEnd: 3,
   *       },
   *       breakpointId: 'someid',
   *     },
   *   ],
   * });
   * @param token - app token - not in use
   * @param options -
   * @param options.componentRef - component reference for the grid child component.
   * @description Updates positions of the specified component in its parent grid container,
   * in all breakpoints where you need it to be updated. Notice that xStart/xEnd fields
   * start numeration from 1 and should end after the last row, i.e. if we define a component
   * that takes one column it can have columnStart=1 and columnEnd=2.
   * @returns A promise that resolves once the operation is done.
   */
  function updateChildPositions(
    token: string,
    options: updateChildPositionsOptions,
  ): Promise<void> {
    return getAPI().then((api) =>
      api.responsive.grid.updateChildPositions(appData, token, options),
    )
  }

  /**
   * @doc Grid
   * @note `Editor X`
   * @example
   * await editorSDK.responsive.grid.deleteChild(token, {
   *   componentRef,
   * });
   * @param token - app token - not in use
   * @param options -
   * @param options.componentRef - component reference for the grid child component.
   * @description Deletes a child component from the grid container and updates the container layout
   * to reflect the changes. For example, it removes a row if it doesn't contain any components.
   * @returns A promise that resolves once the operation is done.
   */
  function deleteChild(
    token: string,
    options: ComponentRefOptions,
  ): Promise<void> {
    return getAPI().then((api) =>
      api.responsive.grid.deleteChild(appData, token, options),
    )
  }

  /**
   * @doc Grid
   * @note `Editor X`
   * @example
   * await editorSDK.responsive.grid.addChild(token, {
   *   containerRef: containerRef,
   *   componentDefinition: legacyCompDefinition,
   *   customId: 'someid',
   *   positions: [
   *     {
   *       gridArea: {
   *         rowStart: 1,
   *         rowEnd: 2,
   *         columnStart: 1,
   *         columnEnd: 3,
   *       },
   *       breakpointId: undefined,
   *     },
   *     {
   *       gridArea: {
   *         rowStart: 2,
   *         rowEnd: 3,
   *         columnStart: 1,
   *         columnEnd: 3,
   *       },
   *       breakpointId: 'someid',
   *     },
   *   ],
   * });
   * @param token - app token - not in use
   * @param options -
   * @param options.containerRef - component reference for the grid container component.
   * @param options.componentDefinition - component definition for the component to add.
   * @param options.customId - custom ID of the component, if required.
   * @param options.positions - component positions in grid container in all breakpoints where you want to defined the child position.
   * @param options.keepStructure - defines whether to use passed component structure or let the editor calculate it.
   * @param options.focusAfterAdding - defines whether the added child should have focus.
   * @description Adds a new component into the grid container and updates the item layout so that it will be
   * positioned correctly in the grid.
   * @returns A promise that resolves to the added component reference.
   */
  function addChild(
    token: string,
    options: addChildOptions,
  ): Promise<ComponentRef> {
    return getAPI().then((api) =>
      api.responsive.grid.addChild(appData, token, options),
    )
  }

  return {
    grid: {
      addGridContainer,
      getLayouts,
      updateLayout,
      updateGaps,
      addRow,
      addColumn,
      removeRow,
      removeColumn,
      getChildPositions,
      updateChildPositions,
      deleteChild,
      addChild,
    },
  }
}
