let queryMap

export function getQueryParameter(parameterName) {
  if (!queryMap) {
    queryMap = {}
    var queryString = location.search.substring(1) || ''
    var queryArray = queryString.split('&')

    queryArray.forEach(function (element) {
      var parts = element.split('=')
      queryMap[parts[0]] = decodeURIComponent(parts[1])
    })
  }
  return queryMap[parameterName] || null
}
