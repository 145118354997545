import {TransactionError} from '@wix/editor-platform-sdk-types'
import {getAPI} from '../../privates/editorAPI'
import apiWrapper from '../../privates/apiWrapper'

export default function (appData) {
  /**
   * @doc Transactions
   * @description Run a *transaction* and wait for approval or rejection.
   * A transaction is a set of functions that need to be performed together, or not at all, which results in a rollback of the functions that had been completed.
   * A transaction is approved by `runAndWaitForApproval` if it is logically valid and applied without resulting in an exception or in a conflict caused by concurrent editing.
   * In the case of a conflict we recommend that you retry the transaction. Otherwise, handle exceptions as you would normally.
   * You can distinguish between conflicts and other errors by using the [EditorSDK.document.transactions.isConflictError](#isConflictError) method. [Learn more](../articles/concurrent.md)
   * @example editorSDK.document.transactions.runAndWaitForApproval('token', () => editorSDK.document.pages.add('token',  { title: 'new page', definition: pageDefinition, shouldAddMenuItem: false}))
   * @param token - app token - not in use
   * @param action -
   *  - A function that is treated as transaction.
   * @returns A promise that returns the value of the transaction.
   */
  function runAndWaitForApproval(
    token: string,
    action: () => Promise<any> | any,
  ): Promise<any> {
    return apiWrapper.waitForAllChanges().then(() =>
      getAPI().then((api) => {
        return api.document.transactions
          .runAndWaitForApproval(appData, token, action)
          .catch((error) => {
            apiWrapper.clearQueue()
            return Promise.reject(error)
          })
      }),
    )
  }

  /**
   * @doc Transactions
   * @description Function to help you distinguish between regular and conflict errors.
   * Use this function only when [editorSDK.document.transactions.runAndWaitForApproval](#runAndWaitForApproval) is rejected. [Learn more](../articles/concurrent.md)
   * @example
   * try {
   *  editorSDK.document.transactions.runAndWaitForApproval('token', () => editorSDK.document.pages.add('token',  { title: 'new page', definition: pageDefinition, shouldAddMenuItem: false} ));
   * } catch (e) {
   *   const isTransactionError = await editorSDK.document.transactions.isConflictError('token', e);
   *   if (isTransactionError) {
   *       console.log('handle conflict error')
   *   } else {
   *       console.log('error handling here')
   *   }
   * }
   * @returns a Class of conflict error
   */
  function isConflictError(
    token: string,
    error: TransactionError,
  ): Promise<boolean> {
    const isConflictError = error.type === 'TransactionRejectionError'
    return Promise.resolve(isConflictError)
  }

  return {
    runAndWaitForApproval,
    isConflictError,
  }
}
