import {getAPI} from '../privates/editorAPI'

export default function (appData) {
  /**
   * @doc Account
   * @description Returns whether the user account has a co-branding package purchased from Wix.
   * @example const isCoBranded = await editorSDK.document.account.isCoBranded()
   * @returns A promise that is resolved with a Boolean. *true* indicates that the user has a co-branding agreement with Wix. The default value is *false*.
   */
  function isCoBranded(): Promise<boolean> {
    return getAPI().then((api) => api.document.account.isCoBranded())
  }

  return {
    isCoBranded,
  }
}
