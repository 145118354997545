import {getAPI} from '../../privates/editorAPI'

export default function (appData) {
  /**
   * @doc Ide.TreePane
   * @note `Classic Editor` `Editor X`
   * @description the function returns if the treePane is open or not. returns true if its open and false if its close.
   * @example const isTreePaneOpen = await editorSDK.editor.ide.treePane.isOpen('token')
   * @param {string} token - app token, not in use
   * @returns a promise that is resolved with a boolean value indicating if the treePane is open or not. true if its open, false otherwise.
   */
  function isOpen(token: string): Promise<boolean> {
    return getAPI().then((api) => {
      return api.editor.ide.treePane.isOpen(appData, token)
    })
  }

  /**
   * @doc Ide.TreePane
   * @description the function toggles the treePane visibility. if the tree pane was open it will close it and if it was closed it will be opened.
   * @example editorSDK.editor.ide.treePane.toggle('token')
   * @param {string} token - app token, not in use
   * @returns a promise that is resolved when the treePane was toggled
   */
  function toggle(token) {
    return getAPI().then((api) => {
      return api.editor.ide.treePane.toggle(appData, token)
    })
  }

  return {
    isOpen,
    toggle,
  }
}
