import {getAPI} from '../privates/editorAPI'

export default function (appData) {
  /**
   * @doc environment
   * @description Returns the screen resolution.
   * @example const resolution = await editorSDK.document.environment.screen.getScreenResolution() //{height: 1080, width: 1920}
   * @returns A promise that is resolved with an object describing the physical resolution, in pixels, of the screen displaying the Editor
   */
  function getScreenResolution(): Promise<{width: number; height: number}> {
    return getAPI().then((api) =>
      api.document.environment.screen.getScreenResolution(),
    )
  }

  return {
    screen: {
      getScreenResolution,
    },
  }
}
