import {getAPI} from '../privates/editorAPI'

export default function (appData) {
  /**
   * @doc DeveloperMode
   * @note `Classic Editor` `Editor X`
   * @example
   * const isDeveloperModeEnabled = await editorSDK.editor.developerMode.isEnabled(token);
   * @param token - app token - not in use
   * @description Returns if developer mode is enabled or not.
   * @returns a promise that is resolved with a boolean value indicating if the developer mode is enabled or not. true if its open, false otherwise.
   */
  function isEnabled(token: string): Promise<boolean> {
    return getAPI().then((api) => {
      return api.editor.developerMode.isEnabled(appData, token)
    })
  }

  /**
   * @doc DeveloperMode
   * @note `Classic Editor` `Editor X`
   * @example
   * const isDeveloperModeEnabled = await editorSDK.editor.developerMode.isReadOnly(token);
   * @param token - app token - not in use
   * @description Returns if developer mode is in read only or not. Read only status is truthy when multiple users edit the velo code on a same site with dev mode turned on,
   * for all users other then the first one, the status will be truthy.
   * On classic editor defaults to false as the feature is not yet implemented there.
   * @returns a promise that is resolved with a boolean value indicating if the developer mode is in read only or not. true if its read only, false otherwise.
   */
  function isReadOnly(token: string): Promise<boolean> {
    return getAPI().then((api) => {
      return api.editor.developerMode.isReadOnly(appData, token)
    })
  }

  return {
    isEnabled,
    isReadOnly,
  }
}
