import {AppData, ComponentRef} from '@wix/editor-platform-sdk-types'
import apiWrapper from '../../privates/apiWrapper'

export default function (appData) {
  const VIRTUAL_SLOT_ID_DIVIDER = '_vs_'
  const SLOT_ID_DIVIDER = '_r_'

  function getHostRefId(slotCompRef) {
    const separator = slotCompRef.id.includes(VIRTUAL_SLOT_ID_DIVIDER)
      ? VIRTUAL_SLOT_ID_DIVIDER
      : SLOT_ID_DIVIDER

    return slotCompRef.id.split(separator)[0]
  }

  async function validateAppContext(
    appData: AppData,
    token: string,
    api: any,
    slotCompRef: ComponentRef,
  ) {
    const hostRefId = getHostRefId(slotCompRef)
    const hostRef = await api.document.components.getById(appData, token, {
      id: hostRefId,
    })
    const [componentStructure] = await api.document.components.get(
      appData,
      token,
      {componentRefs: hostRef, properties: ['data']},
    )

    const compAppDefId = componentStructure?.data?.appDefinitionId
    if (!compAppDefId || appData.appDefinitionId !== compAppDefId) {
      throw new Error(
        `Can not call for appDefinitionId: ${appData.appDefinitionId} because componentRef belongs to appDefinitionId: ${compAppDefId}`,
      )
    }
  }

  async function getWidgetRefAndTypeByWidgetId(
    appData: AppData,
    token: string,
    api: any,
    widgetId: string,
  ): Promise<{
    widgetRef: ComponentRef
    isOOI: boolean
  }> {
    const componentRefs = await api.document.components.getAllComponents(
      appData,
      token,
    )
    const componentsOfType = []
    await Promise.all(
      componentRefs.map(async (componentRef) => {
        const componentType = await api.document.components.getType(
          appData,
          token,
          {componentRef},
        )
        const possibleHostTypes = [
          'wysiwyg.viewer.components.RefComponent', // blocks host
          'wysiwyg.viewer.components.tpapps.TPAWidget', // ooi host
          'wysiwyg.viewer.components.tpapps.TPAMultiSection', // ooi host
        ]
        if (possibleHostTypes.includes(componentType)) {
          componentsOfType.push(componentRef)
        }
      }),
    )

    const withData = await Promise.all(
      componentsOfType.map(async (compRef) => ({
        compRef,
        data: await api.document.components.data.get(appData, token, {
          componentRef: compRef,
        }),
      })),
    )
    const found = withData.find((d) => d.data.widgetId === widgetId)
    if (!found) {
      throw new Error(`Can not find widgetRef for widgetId: ${widgetId}}`)
    }
    return {widgetRef: found.compRef, isOOI: found.data.type === 'TPAWidget'}
  }

  async function getSlotRefBySlotIdAndWidgetId(
    token: string,
    slotId: string,
    widgetId: string,
  ): Promise<ComponentRef> {
    const availableSlots = await getWidgetSlots(token, {widgetId: widgetId})
    return availableSlots.find(({placement}) => placement.slotId === slotId)
      ?.compRef
  }

  function extractSlotId(
    options:
      | {
          widgetId: string
          slotId: string
        }
      | {
          widgetId: string
          slotRole: string
        },
  ): string {
    if ('slotRole' in options) {
      console.warn('Parameter "slotRole" is deprecated, use "slotId" instead')
      return options.slotRole
    }
    return options.slotId
  }

  /**
   * @doc WidgetPlugins
   * @description Get available slots of the widget.
   * You may pass a widgetId or a widgetRef (with widgetRef it will be faster internally)
   * @example const slots = await editorSDK.document.tpa.widgetPlugins.getWidgetSlots('token', {widgetId: '69b16fab-d7f5-42df-9f81-dff31180f272'});
   * // or
   * @example const slots = await editorSDK.document.tpa.widgetPlugins.getWidgetSlots('token', {widgetRef: {id: 'comp-l5gtewjo', type: 'DESKTOP'}});
   * @param token - app token - not in use
   * @param options -
   * - widgetId: Id of the host widget
   * - widgetRef: Object that references the widget
   * @returns A promise that is resolved with the slots array that includes their references and additional information
   */
  function getWidgetSlots(
    token,
    options: {widgetId: string} | {widgetRef: ComponentRef},
  ): Promise<
    {
      compRef: ComponentRef
      role: string // deprecated, use placement.slotId instead
      interfaces: string[]
      placement: {
        appDefinitionId: string
        widgetId: string
        slotId: string
      }
      pluginInfo?: {
        widgetId: string
        appDefinitionId: string
        name: string
        description: string
        logoUrl: string
      }
    }[]
  > {
    // @ts-expect-error additional runtime check
    const bothParams = options.widgetRef && options.widgetId
    // @ts-expect-error additional runtime check
    const noParams = !options.widgetRef && !options.widgetId
    if (bothParams || noParams) {
      throw new Error(
        'To define a host widget please provide either a widgetId or a widgetRef.',
      )
    }
    async function withApi(api) {
      let widgetRef: ComponentRef
      if ('widgetRef' in options) {
        widgetRef = options.widgetRef
      }
      if ('widgetId' in options) {
        widgetRef = (
          await getWidgetRefAndTypeByWidgetId(
            appData,
            token,
            api,
            options.widgetId,
          )
        ).widgetRef
      }
      return api.document.tpa.widgetPlugins.getWidgetSlots(appData, token, {
        widgetRef,
      })
    }
    return apiWrapper.dsGetter(
      {
        operationTypes: apiWrapper.OPERATION_TYPES.COMP,
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      withApi,
    )
  }

  function validatePluginActionOptions(options) {
    const viaSlotId =
      options.widgetId &&
      (options.slotRole || options.slotId) &&
      !options.slotCompRef
    const viaRef =
      options.slotCompRef &&
      !options.widgetId &&
      !options.slotId &&
      !options.slotRole
    if (!viaSlotId && !viaRef) {
      throw new Error(
        'To define a slot please provide either a slotCompRef or a pair of slotId and widgetId.',
      )
    }
  }

  async function isWixTpa(
    appData: AppData,
    token: string,
    api: any,
    appDefinitionId: string,
  ) {
    const appCSMData = await api.document.tpa.app.getAppDataByAppDefId(
      appData,
      token,
      appDefinitionId,
    )
    if (typeof appCSMData?.isWixTPA !== 'undefined') {
      return appCSMData.isWixTPA
    }
    const marketData = await api.document.tpa.app.getAppMarketDataByAppDefId(
      appData,
      token,
      appDefinitionId,
    )
    return marketData.by === 'Wix'
  }

  /**
   * @doc WidgetPlugins
   * @description Installs a widget plugin to a slot.
   * If the slot is already taken, it the old plugin will be replaced with the new one.
   * You may pass a widgetId + slotId pair or a slotCompRef (with slotCompRef it will be faster internally)
   * @example await editorSDK.document.tpa.widgetPlugins.addWidgetPlugin('', {slotId: 'slotsPlaceholder1', widgetId: '7b9f0c40-9360-43ee-9318-10bf96fb0af5', widgetPluginPointer: { appDefinitionId: 'e9d7b74f-373b-45ea-aed8-56baba7c7fb9', widgetId: '86fbaa17-58d2-4bab-a8c0-94c92e1521ca'}});
   * // or
   * @example await editorSDK.document.tpa.widgetPlugins.addWidgetPlugin('', {slotCompRef: {id: 'comp-l5gtewjo_r_comp-l3l9hvoq', type: 'DESKTOP'}, widgetPluginPointer: { appDefinitionId: 'e9d7b74f-373b-45ea-aed8-56baba7c7fb9', widgetId: '86fbaa17-58d2-4bab-a8c0-94c92e1521ca'}});
   * @param token - app token - not in use
   * @param options -
   * - slotId: Id of the Slot inside the host widget (deprecated slotRole)
   * - widgetId: Id of the host widget
   * - slotCompRef: Object that references the slot
   * - widgetPluginPointer: Object contains the appDefinitionId and the widgetId of the plugin
   * @returns A promise that is resolved when the plugin is installed
   */
  function addWidgetPlugin(
    token,
    options: (
      | {
          widgetId: string
          slotId: string
        }
      | {
          widgetId: string
          slotRole: string // deprecated, use slotId instead
        }
      | {
          slotCompRef: ComponentRef
        }
    ) & {
      widgetPluginPointer: {
        appDefinitionId: string
        widgetId: string
      }
    },
  ): Promise<void> {
    validatePluginActionOptions(options)
    async function withApi(api) {
      const isWixTPA = await isWixTpa(
        appData,
        token,
        api,
        options.widgetPluginPointer.appDefinitionId,
      )
      if (!isWixTPA) {
        throw new Error('Can not add non Wix App plugin via platform SDK')
      }

      let slotCompRef: ComponentRef
      if ('slotCompRef' in options) {
        slotCompRef = options.slotCompRef
      } else {
        slotCompRef = await getSlotRefBySlotIdAndWidgetId(
          token,
          extractSlotId(options),
          options.widgetId,
        )
      }
      await validateAppContext(appData, token, api, slotCompRef)

      return api.document.tpa.widgetPlugins.addWidgetPlugin(
        appData,
        token,
        {
          slotCompRef,
          widgetPluginPointer: options.widgetPluginPointer,
        },
        {origin: 'sdk'},
      )
    }
    return apiWrapper.dsSetter(
      {
        compRefsToAwait:
          'slotCompRef' in options ? options.slotCompRef : undefined,
        operationTypes: apiWrapper.OPERATION_TYPES.COMP,
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      withApi,
    )
  }

  /**
   * @doc WidgetPlugins
   * @description Removes a widget plugin from the slot.
   * You may pass a widgetId + slotId pair or a slotCompRef (with slotCompRef it will be faster internally)
   * @example await editorSDK.document.tpa.widgetPlugins.removeWidgetPlugin('', {slotId: 'slotsPlaceholder1', widgetId: '7b9f0c40-9360-43ee-9318-10bf96fb0af5'});
   * // or
   * @example await editorSDK.document.tpa.widgetPlugins.removeWidgetPlugin('', {slotCompRef: {id: 'comp-l5gtewjo_r_comp-l3l9hvoq', type: 'DESKTOP'}});
   * @param token - app token - not in use
   * @param options -
   * - slotId: Id of the Slot inside the host widget (deprecated slotRole)
   * - widgetId: Id of the host widget
   * - slotCompRef: Object that references the slot
   * @returns A promise that is resolved when the plugin is removed
   */
  function removeWidgetPlugin(
    token,
    options:
      | {widgetId: string; slotId: string}
      | {widgetId: string; slotRole: string} // deprecated, use slotId instead
      | {slotCompRef: ComponentRef},
  ): Promise<void> {
    validatePluginActionOptions(options)
    async function withApi(api) {
      let slotCompRef: ComponentRef
      if ('slotCompRef' in options) {
        slotCompRef = options.slotCompRef
      } else {
        slotCompRef = await getSlotRefBySlotIdAndWidgetId(
          token,
          extractSlotId(options),
          options.widgetId,
        )
      }
      await validateAppContext(appData, token, api, slotCompRef)
      return api.document.tpa.widgetPlugins.removeWidgetPlugin(appData, token, {
        slotCompRef,
      })
    }
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait:
          'slotCompRef' in options ? options.slotCompRef : undefined,
        operationTypes: apiWrapper.OPERATION_TYPES.COMP,
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      withApi,
    )
  }

  return {
    addWidgetPlugin,
    getWidgetSlots,
    removeWidgetPlugin,
  }
}
