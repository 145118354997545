import {ComponentRef} from '@wix/editor-platform-sdk-types'
import apiWrapper from '../../privates/apiWrapper'

export default function (appData) {
  const compOperationType = apiWrapper.OPERATION_TYPES.COMP

  /**
   * @description Get the properties of the given componentRef.
   * @doc Properties
   * @example
   * const componentRef = await editorSDK.document.components.getById('token', {id: 'comp-jhq8y9hp'});
   * const properties = await editorSDK.components.properties.get('token', {componentRef: componentRef});
   * @param token - app token - not in use
   * @param options -
   * - componentRef: the component ref.
   * @returns a promise that is resolved with the data of the given componentRef.
   */
  function get(token, options: {componentRef: ComponentRef}) {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) => api.document.components.properties.get(appData, token, options),
    )
  }

  /**
   * @description Update the properties of the given componentRef.
   * @doc Properties
   * @example
   const componentRef = await editorSDK.document.components.getById('token', {id: 'comp-jhq8y9hp'});
   await editorSDK.components.properties.update('token', {componentRef: componentRef, props: {align: 'center'}});
   * @param token - app token - not in use
   * @param options -
   * - componentRef: the component ref.
   * - props: an object containing the properties fields to update.
   * @returns a promise that is resolved when the changes has applied to the document.
   */
  function update(token, options: {componentRef: ComponentRef; props: any}) {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) =>
        api.document.components.properties.update(appData, token, options),
    )
  }

  return {
    get,
    update,
  }
}
