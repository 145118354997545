import queue from './SDKoperationsQueue'
import {getAPI} from './editorAPI'

function dsSetter(queueDefinitions, sdkFunc) {
  const compRefPromise = getAPI().then((api) =>
    queue.waitForChanges(
      queueDefinitions,
      () => sdkFunc(api),
      sdkFunc.toString(),
    ),
  )
  compRefPromise.then((ref) =>
    queue.addOperation(ref.id ? ref.id : ref, queueDefinitions.operationTypes),
  )
  return compRefPromise
}

function dsGetter(queueDefinitions, sdkFunc) {
  return getAPI().then((api) =>
    queue.waitForChanges(
      queueDefinitions,
      () => sdkFunc(api),
      sdkFunc.toString(),
    ),
  )
}

function dsUpdater(queueDefinitions, sdkFunc) {
  const resPromise = getAPI().then((api) =>
    queue.waitForChanges(
      queueDefinitions,
      () => sdkFunc(api),
      sdkFunc.toString(),
    ),
  )
  resPromise.then(() => {
    if (queueDefinitions.compRefsToAwait) {
      queueDefinitions.compRefsToAwait = Array.isArray(
        queueDefinitions.compRefsToAwait,
      )
        ? queueDefinitions.compRefsToAwait
        : [queueDefinitions.compRefsToAwait]
      queueDefinitions.compRefsToAwait.forEach((ref) =>
        queue.addOperation(ref, queueDefinitions.operationTypes),
      )
    }
  })
  return resPromise
}

export default {
  dsSetter,
  dsGetter,
  dsUpdater,
  OPERATION_TYPES: queue.OPERATION_TYPES,
  WAITING_TYPES: queue.WAITING_TYPES,
  waitForAllChanges: queue.waitForAllChanges,
  clearQueue: queue.clearQueue,
}
