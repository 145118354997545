import {ComponentRef} from '@wix/editor-platform-sdk-types'
import {getAPI} from '../privates/editorAPI'

export default function (appData) {
  type GetViewStateOptions = {
    componentRef: ComponentRef
  }

  /**
   * @doc Widgets
   * @note `Classic Editor` `Editor X`
   * @example
   * const state = await editorSDK.editor.widgets.getViewState('token', { componentRef: { id: 'component-id', type: 'DESKTOP' } });
   * @param {string} _token - app token, not in use
   * @param options -
   * - `options.componentRef` (string): The widget's component ref
   * @description Returns current view state of the widget.
   * @returns A Promise that is resolved with current view state of the widget.
   */
  async function getViewState(_token: string, options: GetViewStateOptions) {
    const api = await getAPI()
    return api.editor.widgets.getViewState(appData, options.componentRef)
  }

  type SetViewStateOptions = {
    componentRef: ComponentRef
    viewState: string
  }

  /**
   * @doc Widgets
   * @note `Classic Editor` `Editor X`
   * @example
   * await editorSDK.editor.widgets.setViewState('token', { componentRef: { id: 'comp-lbnjj9v0', type: 'DESKTOP' }, viewState: 'error' });
   * @param {string} _token - app token, not in use
   * @param options -
   * - `options.componentRef` (string): The widget's component ref
   * - `options.viewState` (string): State name
   * @description Updates the view state of the widget according to the defined view states in the app manifest. Also emits the `viewStateChanged` event so that an application can subscribe and react to it, and triggers the app's `updateWidgetViewState` viewer controller callback.
   * @returns A Promise that is resolved when the view state of the widget has been updated.
   */
  async function setViewState(_token: string, options: SetViewStateOptions) {
    const {componentRef, viewState} = options
    const api = await getAPI()
    await api.editor.widgets.setViewState(appData, componentRef, viewState)
  }

  return {
    getViewState,
    setViewState,
  }
}
