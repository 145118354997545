import apiWrapper from '../privates/apiWrapper'
import {isWebWorker} from '../privates/utils'
import {
  AppData,
  ComponentRef,
  ContextAwareOptions,
  SDKContext,
  SDKDefaultContext,
} from '@wix/editor-platform-sdk-types'
import {EditorSdkAdapter} from './editorSdkAdapter'
import {resolveOption} from '../../utils/utils'

export default function <Context extends SDKContext = SDKDefaultContext>(
  appData: AppData,
) {
  /**
   * @doc AppSettings
   * @note `Classic Editor` `Editor X`
   * @example
   * const sdkAdapter = await editorSDK.appSettings.createSdkAdapter(token, {controllerRef});
   * @param token - app token - not in use
   * @param options -
   *  - controllerRef - A reference to the controller to which the *app settings* SDK adapter is bound.
   *  - appDefinitionId (Required only in Editor Extensions context): The unique ID of the controller's application.
   * @description Returns an instance of EditorSdkAdapter bound to the specific controller. [Learn more](../articles/app-settings.md) about the app settings tool.
   * @returns A promise to the EditorSdkAdapter instance bound to the specified controller.
   */
  function createSdkAdapter(
    token: string,
    options: ContextAwareOptions<
      Context,
      {controllerRef: ComponentRef},
      {appDefinitionId: string}
    >,
  ): Promise<EditorSdkAdapter> {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: options.controllerRef,
        operationTypes: apiWrapper.OPERATION_TYPES.COMP,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) =>
        Promise.all([
          api.document.info.getAppInstance(appData, options),
          api.document.info.getAppInstanceId(appData, options),
        ]).then(([signedInstance, instanceId]) => {
          const appDefId = resolveOption(appData, options, 'appDefinitionId', {
            isRequired: true,
          })
          return new EditorSdkAdapter({
            signedInstance,
            editorSdkInstance: {
              getExternalId: () =>
                api.document.controllers.getExternalId(appData, token, {
                  controllerRef: options.controllerRef,
                }),
              setExternalId: (externalId) =>
                api.document.controllers.setExternalId(appData, token, {
                  controllerRef: options.controllerRef,
                  externalId,
                }),
              getAppDefId: () => appDefId,
              getInstanceId: () => instanceId,
              triggerUpdate: () => {
                /*TODO: trigger live preview refresh event*/
              },
            },
          })
        }),
    )
  }

  /**
   * @doc AppSettings
   * @example
   * const apiUrl = await editorSDK.appSettings.getApiUrl();
   * @description Returns the app-settings API URL based on the current window/worker. [Learn more](../articles/app-settings.md) about the app settings tool.
   * @returns The app-settings API URL string.
   */
  function getApiUrl(): string {
    const origin = isWebWorker() ? self.location.origin : window.location.origin
    return origin + '/_api/app-settings-service'
  }

  return {
    createSdkAdapter,
    getApiUrl,
  }
}
