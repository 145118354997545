import {ComponentRef, Design} from '@wix/editor-platform-sdk-types'
import apiWrapper from '../../privates/apiWrapper'

export type DesignUpdateObject = Partial<Design>

export default function (appData) {
  const compOperationType = apiWrapper.OPERATION_TYPES.COMP

  /**
   * @description Get the design of the given component.
   * @doc Design
   * @example
   * const componentRef = await editorSDK.document.components.getById('token', {id: 'comp-jhq8y9hp'});
   * const design = await editorSDK.components.design.get('token', {componentRef: componentRef});
   * @param token - app token - not in use
   * @param options -
   * - `componentRef`: the component ref.
   * @returns a promise that is resolved with the design of the given component.
   */
  function get(token, options: {componentRef: ComponentRef}): Promise<Design> {
    return apiWrapper.dsGetter(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
        waitingType: apiWrapper.WAITING_TYPES.COMPS,
      },
      (api) => api.document.components.design.get(appData, token, options),
    )
  }

  /**
   * @description Update the design of the given component.
   * @doc Design
   * @example
   const componentRef = await editorSDK.document.components.getById('token', {id: 'comp-jhq8y9hp'});
   await editorSDK.components.design.update('token', {componentRef: componentRef, design: {}});
   * @param token - app token - not in use
   * @param options -
   * - `componentRef`: the component ref.
   * - `design`: an object containing the design fields to update.
   * @returns a promise that is resolved when the changes has applied to the document.
   */
  function update(
    token,
    options: {componentRef: ComponentRef; design: DesignUpdateObject},
  ): Promise<void> {
    return apiWrapper.dsUpdater(
      {
        compRefsToAwait: options.componentRef,
        operationTypes: compOperationType,
      },
      (api) => api.document.components.design.update(appData, token, options),
    )
  }

  return {
    get,
    update,
  }
}
