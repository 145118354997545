import {getAPI} from '../../privates/editorAPI'
import Add from './add'
import App from './app'
import Data from './data'
import WidgetPlugins from './widgetPlugins'
import {
  TPAComponentType,
  ComponentRef,
  SiteTextPresets,
  SiteColor,
  SDKContext,
  SDKDefaultContext,
  ContextAwareOptions,
  AppData,
} from '@wix/editor-platform-sdk-types'
import {StyleParam, StyleParams, PageRef} from '@wix/editor-platform-sdk-types'
export default function <Context extends SDKContext = SDKDefaultContext>(
  appData: AppData,
) {
  /**
   * @doc TPA
   * @example
   * const isMembersInstalled = editorSDK.document.tpa.isApplicationInstalled(token, {
   *   appDefinitionId
   * });
   * @param token - app token - not in use
   * @param options -
   *  - appDefinitionId - The appDefinitionId of the app in question
   * @description Returns whether the specified application is installed
   * @returns A promise to whether the app definition ID is installed.
   */
  function isApplicationInstalled(
    token,
    options: {appDefinitionId: string},
  ): Promise<boolean> {
    return getAPI().then((api) => {
      return api.document.tpa.isApplicationInstalled(appData, token, options)
    })
  }

  /**
   * @doc TPA
   * @example
   * const sectionId = 'section-id'
   * const isSectionInstalled = editorSDK.tpa.isAppSectionInstalled(token, {
   *   sectionId,
   *   appDefinitionId
   * })
   * @param token - app token - not in use
   * @param options
   *  - sectionId - The section ID to check
   *  - appDefinitionId - The app definition ID to check. Required for Extensions context
   * @description Returns whether a specific section is installed.
   * If no appDefinitionId is passed, the calling app is used.
   * @returns A promise to whether the specific section is installed
   */

  function isAppSectionInstalled(
    token,
    options: ContextAwareOptions<
      Context,
      {sectionId: string; appDefinitionId?: string},
      {appDefinitionId: string}
    >,
  ): Promise<boolean> {
    return getAPI().then((api) => {
      return api.document.tpa.isAppSectionInstalled(appData, token, options)
    })
  }

  /**
   * @doc TPA
   * @example
   * editorSDK.document.tpa.setStyleParams(token, {
   *   compRef: componentRef,
   *   styleParams: [
   *     {
   *       type: 'number',
   *       key: 'some-number-setting-key',
   *       param: {
   *         value: 3
   *       }
   *     },
   *     {
   *       type: 'color',
   *       key: 'some-color-setting-key',
   *       param: {
   *         value: {
   *           color: false,
   *           opacity: 0.4,
   *           rgba: 'rgba(70,88,41,0.80)'
   *         }
   *       }
   *     },
   *     {
   *       type: 'color',
   *       key: 'some-other-color-setting-key',
   *       param: {
   *         value: {
   *           color: {
   *             value: 'val',
   *             name: 'color_15'
   *           },
   *           opacity: 0.4
   *         }
   *       }
   *     }
   *   ]
   * })
   * @param token - app token - not in use
   * @param options
   * @param options.compRef - The ref of the component to set the styles to
   * @param options.styleParams - An array of style params that should be set
   * @description Sets style params for the given TPA component.
   * The style param depends on the type of it, see all the possible types in js-platform-editor-sdk typings
   * @returns A promise which resolves when the action is done
   */

  function setStyleParams(
    token,
    options: {
      compRef: ComponentRef
      styleParams: StyleParam[]
      isGlobal?: boolean
    },
  ): Promise<void> {
    return getAPI().then((api) => {
      return api.document.tpa.setStyleParams(appData, token, options)
    })
  }

  /**
   * @doc TPA
   * @example
   * editorSDK.document.tpa.getStyleParams(token, {
   *   compRef: componentRef
   * })
   * @param token - app token - not in use
   * @param options
   * @param options.compRef - The ref of the component to get the style params from
   * @description Gets the style params of the given component.
   * @returns A promise with component style params
   */

  function getStyleParams(
    token,
    options: {compRef: ComponentRef},
  ): Promise<StyleParams> {
    return getAPI().then((api) => {
      return api.document.tpa.getStyleParams(appData, token, options)
    })
  }

  /**
   * @doc TPA
   * @example const siteTextPresets = await editorSDK.document.tpa.getSiteTextPresets(token, { compRef: componentRef })
   * @param token - app token - not in use
   * @param options -
   * @param options.compRef - The reference to the component to get the site text presets from.
   * @description Gets the site text style presets for the given component.
   * @returns A promise with an object containing the site text presets.
   */
  function getSiteTextPresets(
    token,
    options: {compRef: ComponentRef},
  ): Promise<SiteTextPresets> {
    return getAPI().then((api) =>
      api.document.tpa.getSiteTextPresets(appData, token, options),
    )
  }

  /**
   * @doc TPA
   * @example const siteColors = await editorSDK.document.tpa.getSiteColors(token, { compRef: componentRef })
   * @param token - app token - not in use
   * @param options -
   * @param options.compRef - The reference to the component to get the site colors from.
   * @description Gets the site colors for the given component.
   * @returns A promise with an object containing the site colors.
   */
  function getSiteColors(
    token,
    options: {compRef: ComponentRef},
  ): Promise<SiteColor[]> {
    return getAPI().then((api) =>
      api.document.tpa.getSiteColors(appData, token, options),
    )
  }

  /**
   * @doc TPA
   * @example const pageRef = await editorSDK.document.tpa.getPageRefByTPAPageId(token, { tpaPageId: 'thank_you_page' })
   * @param token - app token - not in use
   * @param options -
   * @param options.tpaPageId - The string that represent the unique tpaPageId listed on the pageData.
   * @description the method returns the pageRef of the page the match the tpaPageId.
   * @returns A promise with the pageRef of the page the match the tpaPageId.
   */
  function getPageRefByTPAPageId(
    token,
    options: {tpaPageId: string},
  ): Promise<PageRef> {
    return getAPI().then((api) =>
      api.document.tpa.getPageRefByTPAPageId(appData, token, options),
    )
  }

  return {
    add: Add<Context>(appData),
    app: App<Context>(appData),
    data: Data(appData),
    widgetPlugins: WidgetPlugins(appData),
    isApplicationInstalled,
    isAppSectionInstalled,
    setStyleParams,
    getStyleParams,
    getSiteTextPresets,
    getSiteColors,
    getPageRefByTPAPageId,
    TPAComponentType,
  }
}
