import {getAPI} from '../privates/editorAPI'
import {ComponentRef, PageRef} from '@wix/editor-platform-sdk-types'

export default function (appData) {
  /**
   * @doc SiteSegments
   * @description the function returns the component reference that describes the site footer.
   * @example const footerRef = await editorSDK.document.siteSegments.getFooter('token');
   * @param token - app token - not in use
   * @returns a promise with component reference that describes the site footer.
   */
  function getFooter(token: string): Promise<ComponentRef> {
    return getAPI().then((api) => {
      return api.document.siteSegments.getFooter(appData, token)
    })
  }

  /**
   * @doc SiteSegments
   * @description the function returns the component reference that describes the site header.
   * @example const headerRef = await editorSDK.document.siteSegments.getHeader('token');
   * @param token - app token - not in use
   * @returns a promise with component reference that describes the site header.
   */
  function getHeader(token: string): Promise<ComponentRef> {
    return getAPI().then((api) => {
      return api.document.siteSegments.getHeader(appData, token)
    })
  }

  /**
   * @doc SiteSegments
   * @description the function returns the component reference that describes the site pages container.
   * @example const pagesContainerRef = await editorSDK.document.siteSegments.getPagesContainer('token');
   * @param token - app token - not in use
   * @returns a promise with component reference that describes the site pagesContainer.
   */
  function getPagesContainer(token: string): Promise<ComponentRef> {
    return getAPI().then((api) => {
      return api.document.siteSegments.getPagesContainer(appData, token)
    })
  }

  /**
   * @doc SiteSegments
   * @description the function returns the pageRef of the masterPage.
   * @example const masterPageRef = await editorSDK.document.siteSegments.getSiteStructure('token');
   * @param token - app token - not in use
   * @returns a promise with the masterPage reference.
   */
  function getSiteStructure(token): Promise<PageRef> {
    return getAPI().then((api) => {
      return api.document.siteSegments.getSiteStructure(appData, token)
    })
  }

  return {
    getFooter,
    getHeader,
    getPagesContainer,
    getSiteStructure,
  }
}
