import {SignUpPrivacyNoteType} from '@wix/editor-platform-sdk-types'
import {getAPI} from '../privates/editorAPI'

export default function (appData) {
  /**
   * @doc SiteMembers
   * @description Get the custom sign-in page ID (a page that is defined in the editor to be shown when a user of user navigates to the login page).
   * @example const pageId = await editorSDK.document.siteMembers.getCustomSignInPageId('token')
   * @param token - app token - not in use
   * @returns a Promise that is resolved with the page ID (null if there is none)
   */
  function getCustomSignInPageId(token): Promise<string | null> {
    return getAPI().then((api) => {
      return api.document.siteMembers.getCustomSignInPageId(appData)
    })
  }

  /**
   * @doc SiteMembers
   * @description Get the custom signup page ID (a page that is defined in the editor to be shown when a user of user navigates to the signup page).
   * @example const pageId = await editorSDK.document.siteMembers.getCustomSignupPageId('token')
   * @param token - app token - not in use
   * @returns a Promise that is resolved with the page ID (null if there is none)
   */
  function getCustomSignupPageId(token): Promise<string | null> {
    return getAPI().then((api) => {
      return api.document.siteMembers.getCustomSignupPageId(appData)
    })
  }

  /**
   * @doc SiteMembers
   * @description Get the custom 'no permissions' page ID (a page that is defined in the editor to be shown when a user of user navigates to a restricted page).
   * @example const pageId = await editorSDK.document.siteMembers.getCustomNoPermissionsPageId('token')
   * @param token - app token - not in use
   * @returns a Promise that is resolved with the page id (null if theres none)
   */
  function getCustomNoPermissionsPageId(token): Promise<string | null> {
    return getAPI().then((api) => {
      return api.document.siteMembers.getCustomNoPermissionsPageId(appData)
    })
  }

  /**
   * @doc SiteMembers
   * @description Set the custom 'no permissions' pageId
   * @example await editorSDK.document.siteMembers.setCustomNoPermissionsPageId('token', {pageId: 'pageId'})
   * @param token - app token - not in use
   * @param options -
   *  - pageId: page id to set as the custom no permissions page.
   * @returns a Promise that is resolved on page changed
   */
  function setCustomNoPermissionsPageId(
    token,
    options: {pageId: string},
  ): Promise<void> {
    return getAPI().then((api) => {
      return api.document.siteMembers.setCustomNoPermissionsPageId(
        token,
        options.pageId,
      )
    })
  }

  /**
   * @doc SiteMembers
   * @description Set the custom signup page ID
   * @example await editorSDK.document.siteMembers.setCustomSignupPageId('token', {pageId: 'pageId'})
   * @param token - app token - not in use
   * @param options -
   *  - pageId: Page ID to set as the custom signup page.
   * @returns a Promise that is resolved when the page is changed
   */
  function setCustomSignupPageId(
    token,
    options: {pageId: string},
  ): Promise<void> {
    return getAPI().then((api) => {
      return api.document.siteMembers.setCustomSignupPageId(
        token,
        options.pageId,
      )
    })
  }

  /**
   * @doc SiteMembers
   * @description Set the custom sign-in (login) page ID
   * @example await editorSDK.document.siteMembers.setCustomSignInPageId('token', {pageId: 'pageId'})
   * @param token - app token - not in use
   * @param options -
   *  - pageId: Page ID to set as the custom sign-in (login) page.
   * @returns a Promise that is resolved the page is changed
   */
  function setCustomSignInPageId(
    token,
    options: {pageId: string},
  ): Promise<void> {
    return getAPI().then((api) => {
      return api.document.siteMembers.setCustomSignInPageId(
        token,
        options.pageId,
      )
    })
  }

  /**
   * @doc SiteMembers
   * @description Get the privacy note type in the Sign Up form
   * @example await editorSDK.document.siteMembers.getPrivacyNoteType('token')
   * @param token - app token - not in use
   * @returns a Promise that is resolved with the privacy note type
   */
  function getPrivacyNoteType(token): Promise<SignUpPrivacyNoteType> {
    return getAPI().then((api) => {
      return api.document.siteMembers.getPrivacyNoteType()
    })
  }

  /**
   * @doc SiteMembers
   * @description Set the privacy note type in the Sign Up form
   * @example await editorSDK.document.siteMembers.setPrivacyNoteType('token', 'NOTE')
   * @param token - app token - not in use
   * @param privacyNoteType - privacy note type to set (either `'CHECKBOX'` or `'NOTE'`)
   * @returns a Promise that is resolved when the privacy note type is changed
   */
  function setPrivacyNoteType(
    token,
    privacyNoteType: SignUpPrivacyNoteType,
  ): Promise<void> {
    return getAPI().then((api) => {
      return api.document.siteMembers.setPrivacyNoteType(privacyNoteType)
    })
  }

  return {
    getCustomSignupPageId,
    getCustomSignInPageId,
    setCustomSignupPageId,
    setCustomSignInPageId,
    getCustomNoPermissionsPageId,
    setCustomNoPermissionsPageId,
    getPrivacyNoteType,
    setPrivacyNoteType,
  }
}
