import {getAPI} from '../../privates/editorAPI'
import {
  ComponentRef,
  TPAPublicDataScope,
  TPAPublicDataValue,
  TPAPublicData,
  TPAPublicDataEntry,
} from '@wix/editor-platform-sdk-types'

export default function (appData) {
  /**
   * @doc PublicData
   * @description Set the TPA's public data. The data is associated with the app. Each entry consists of a key and a value.
   * @example const publicAppData = await editorSDK.document.tpa.data.setAppPublicData(
   *     'token',
   *     {
   *         key: 'tpa-data-key',
   *         value: 'tpa-data-value',
   *     })
   * @param token - app token - not in use
   * @param options -
   * - key: Public data entry key (string).
   * - value: Public data entry value (number, Boolean, string, json object or an array of one of those types).
   * @returns A promise that is resolved with an object containing all of the public data of the TPA and/or its components.
   */
  function setAppPublicData(
    token: string,
    options: {
      key: string
      value: TPAPublicDataValue
    },
  ): Promise<Record<string, TPAPublicDataValue>> {
    return getAPI().then((api) =>
      api.document.tpa.data.setAppPublicData(appData, token, options),
    )
  }

  /**
   * @doc PublicData
   * @description Gets the TPA's public data associated with the TPA. Each entry consists of a key and a value.
   * @example const publicAppData = await editorSDK.document.tpa.data.getAppPublicData('token', {key: 'tpa-data-key'})
   * @param token - app token - not in use
   * @param options -
   * - key: Public data entry key (string).
   * @returns A promise that is resolved with an object containing all of the public data of the TPA and/or its components.
   */
  function getAppPublicData(
    token: string,
    options: {key: string},
  ): Promise<Record<string, TPAPublicDataValue>> {
    return getAPI().then((api) =>
      api.document.tpa.data.getAppPublicData(appData, token, options),
    )
  }

  /**
   * @doc PublicData
   * @description Gets the TPA's public data. This is the data that the user added in the Editor. Gets all of the data associated with the app and/or data associated with the app's components.
   * @example const publicData = await editorSDK.document.tpa.data.getAll('token', {compRef : {id: 'oy3rh', type: 'DESKTOP'}})
   * @param token - app token - not in use
   * @param options -
   * - compRef: Object that references the TPA's component whose data you want to get. This is a required parameter  &mdash; provide a valid value for one of the app's components.
   * @returns A promise that is resolved with an object containing all of the public data of the TPA and/or its components.
   */
  function getAll<
    AppScopeValues extends TPAPublicDataEntry | undefined =
      | TPAPublicDataEntry
      | undefined,
    ComponentScopeValues extends TPAPublicDataEntry | undefined =
      | TPAPublicDataEntry
      | undefined,
  >(
    token: string,
    options: {compRef: ComponentRef},
  ): Promise<TPAPublicData<AppScopeValues, ComponentScopeValues>> {
    return getAPI().then((api) =>
      api.document.tpa.data.getAll(appData, token, options),
    )
  }

  /**
   * @doc PublicData
   * @description Sets the TPA's public data entry. This is the data that the user added in the Editor.
   * The data is associated with the app or with a component in the app, as set by the 'scope' parameter. Each entry consists of a key and a value.
   * @example const publicDataEntry = await editorSDK.document.tpa.data.set(
   *     'token',
   *     {
   *         compRef : {id: 'oy3rh', type: 'DESKTOP'},
   *         key: 'tpa-data-key',
   *         value: 'tpa-data-value',
   *         scope: 'COMPONENT'
   *     })
   * @param token - app token - not in use
   * @param options -
   * - compRef: Object that references the TPA's component whose data you want to set. This is a required parameter &mdash; provide a valid value even when scope is set to *APP*.
   * - key: Public data entry key (string).
   * - value: Public data entry value (number, Boolean, string, json object or an array of one of those types).
   * - scope: Public data entry scope, either APP or COMPONENT (default).
   * @returns A promise that is resolved with the saved public data entry
   */
  function set(
    token: string,
    options: {
      compRef: ComponentRef
      key: string
      value: TPAPublicDataValue
      scope?: TPAPublicDataScope
    },
  ): Promise<TPAPublicDataEntry> {
    return getAPI().then((api) =>
      api.document.tpa.data.set(appData, token, options),
    )
  }

  /**
   * @doc PublicData
   * @description Removes the TPA's public data entry. This is the data that the user added in the Editor.
   * The data is associated with the app or with a component in the app, as set by the 'scope' parameter.
   * @example const publicDataEntry = await editorSDK.document.tpa.data.remove(
   *     'token',
   *     {
   *         compRef : {id: 'oy3rh', type: 'DESKTOP'},
   *         key: 'tpa-data-key',
   *         scope: 'COMPONENT'
   *     })
   * @param token - app token - not in use
   * @param options -
   * - compRef: Object that references the TPA's component whose data you want to remove. This is a required parameter  &mdash; provide a valid value even when scope is set to *APP*.
   * - key: Public data entry key (string).
   * - scope: Public data entry scope (APP | COMPONENT, defaults to COMPONENT). Scope specifies at what level public data will be stored.
   * @returns A promise that is resolved with the removed public data entry.
   */
  function remove(
    token: string,
    options: {
      compRef: ComponentRef
      key: string
      scope?: TPAPublicDataScope
    },
  ): Promise<TPAPublicDataEntry> {
    return getAPI().then((api) =>
      api.document.tpa.data.remove(appData, token, options),
    )
  }

  return {
    setAppPublicData,
    getAppPublicData,
    getAll,
    set,
    remove,
  }
}
