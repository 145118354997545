import {PageRef, Rule} from '@wix/editor-platform-sdk-types'
import apiWrapper from '../privates/apiWrapper'
import {getAPI} from '../privates/editorAPI'

export default function (appData) {
  const compOperationType = apiWrapper.OPERATION_TYPES.COMP
  const routerOperationType = apiWrapper.OPERATION_TYPES.ROUTER
  /**
   * @doc editorRouters
   * @note `Classic Editor` `Editor X`
   * @description This method refreshes the inner routes of the router for an active page.
   * If the active page does not belong to the calling application, the method does nothing.
   * This method works only for dynamic pages. Read more about Routers [here](../articles/routers.md).
   * @example
   * editorSDK.editor.routers.refresh('token')
   * @param token - app token - not in use.
   * @param options
   * selectedRoute: route to navigate after refresh.
   * @returns Promise that returns undefined.
   */
  function refresh(
    token: string,
    options?: {selectedRoute?: string},
  ): Promise<undefined> {
    return getAPI().then((api) =>
      api.editor.routers.refresh(appData, token, options),
    )
  }

  /**
   * @doc editorRouters
   * @note `Classic Editor` `Editor X`
   * @description Replace a default app page with a customized page.
   * @example
   * const pageRef = await editorSDK.document.tpa.getPageRefByTPAPageId(token, { tpaPageId: 'thank_you_page' })
   * const replacerPageRef = await editorSDK.document.pages.add('token',  {
   *    title: 'replacer page', definition: pageDefinition, shouldAddMenuItem: false
   *  })
   * await editorSDK.editor.routers.setReplacerPage('token', {pageRef, replacerPageRef, setAsActive: true})
   * @param token - app token - not in use
   * @param options -
   *  - `pageRef`: The reference to the page that you want to replace.
   *  - `replacerPageRef`: The reference to the page that is replacing the default app page.
   *  - `setAsActive`: If true, define the replacer page as active, otherwise just add it as a connected variation.
   * @returns A promise that is resolved when the replacer is set.
   */
  function setReplacerPage(
    token: string,
    options: {pageRef: PageRef; replacerPageRef: PageRef; setAsActive: boolean},
  ): Promise<void> {
    return apiWrapper.dsUpdater(
      {
        operationTypes: [routerOperationType, compOperationType],
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      (api) => api.editor.routers.setReplacerPage(token, appData, options),
    )
  }

  /**
   * @doc editorRouters
   * @note `Classic Editor` `Editor X`
   * @description Add a variant app page with its rules. The variant page will replace the default page when the conditions are met.
   * @example
   * const pageRef = await editorSDK.document.tpa.getPageRefByTPAPageId(token, { tpaPageId: 'thank_you_page' })
   * const variantPageRef = await editorSDK.document.pages.add('token',  {
   *    title: 'variant page', definition: pageDefinition, shouldAddMenuItem: false
   *  })
   * const rule = {
   *   name: 'variant rule',
   *   conditions:[{field_key: 'location', operator: 'EQ', values: 'US',}],
   *   predicate: 'AND',
   * };
   * const variantId = 'variantId';
   * await editorSDK.editor.routers.addVariantToPage({pageRef, variantPageRef, rule, variantId})
   * @param options -
   *  - `pageRef`: The reference to the page that you want to replace.
   *  - `variantPageRef`: The reference to the page that is replacing the default app page.
   *  - `rule`:
   *  - `variantId`: The variant id was defined in the app manifest.
   * @returns A promise that is resolved when the variant is set.
   */
  function addVariantToPage(options: {
    pageRef: PageRef
    variantPageRef: PageRef
    rule: Rule
    variantId: string
  }): Promise<void> {
    return apiWrapper.dsUpdater(
      {
        operationTypes: [routerOperationType],
        waitingType: apiWrapper.WAITING_TYPES.TYPE,
      },
      (api) =>
        api.editor.routers.addVariantToPage(
          options.pageRef,
          options.variantPageRef,
          options.rule,
          options.variantId,
        ),
    )
  }
  return {
    refresh,
    setReplacerPage,
    addVariantToPage,
  }
}
