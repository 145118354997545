import {getAPI} from '../privates/editorAPI'

export default function (appData) {
  /**
   * @doc Environment
   * @description Returns the editor's language code.
   * Editor's language defines the language of the User Interface of the Editor.
   * All Editor's panels should use this language.
   * @note `Classic Editor` `Editor X`
   * @example
   * const locale = await editorSDK.editor.environment.getLocale();
   * @param token - app token - not in use
   * @returns A promise the is resolved with the string containing editor's language code.
   */
  function getLocale(token?): Promise<string> {
    return getAPI().then((api) => {
      return api.editor.environment.getLocale(appData)
    })
  }

  return {
    getLocale,
  }
}
