import {getAPI} from '../../privates/editorAPI'

export default function (appData) {
  /**
   * @doc AppBuilder
   * @note `Blocks`
   * @example
   * const appName = await editorSDK.appBuilder.appInfo.getName()
   * @description Returns the appName as defined in Dev Center.
   * This function is available only in the Wix Blocks editor.
   * @returns {Promise} A promise that is resolved with the `appName` as a string.
   */
  function getAppName(): Promise<string> {
    return getAPI().then((api) => {
      return api.appBuilder.appInfo.getName()
    })
  }

  /**
   * @doc AppBuilder
   * @note `Blocks`
   * @example
   * const appNamespace = await editorSDK.appBuilder.appInfo.getNamespace()
   * @description Returns the appNamespace, a unique namespace identifier of the created app.
   * This function is available only in the Wix Blocks editor.
   * @returns {Promise} A promise that is resolved with the `appNamespace` as a string.
   */
  function getAppNamespace(): Promise<string> {
    return getAPI().then((api) => {
      return api.appBuilder.appInfo.getNamespace()
    })
  }

  /**
   * @doc AppBuilder
   * @note `Blocks`
   * @example
   * const serializedWidgets = await editorSDK.appBuilder.widgets.getAllSerialized()
   * @param token - app token - not in use
   * @description Returns an array with all the serialized widgets of the created app.
   * This function is available only in the Wix Blocks editor.
   * @returns {Promise} A promise that is resolved with an array of the serialized widgets.
   */
  function getAllSerializedWidgets(token?: string): Promise<object[]> {
    return getAPI().then((api) => {
      return api.appBuilder.widgets.getAllSerialized()
    })
  }

  /**
   * @doc AppBuilder
   * @note `Blocks`
   * @example
   * const serializedDefinitions = await editorSDK.appBuilder.definitions.getAllSerialized()
   * @param token - app token - not in use
   * @description Returns an array with all the serialized custom definitions of the created app.
   * This function is available only in the Wix Blocks editor.
   * @returns {Promise} A promise that is resolved with an array of the serialized custom definitions.
   */
  function getAllSerializedCustomDefinitions(
    token?: string,
  ): Promise<object[]> {
    return getAPI().then((api) => {
      return api.appBuilder.definitions.getAllSerialized()
    })
  }

  /**
   * @doc AppBuilder
   * @note `Blocks`
   * @example
   * const appDefId = await editorSDK.appBuilder.appInfo.getAppDefId()
   * @description Returns the `appDefId` as defined in Dev Center.
   * This function is available only in the Wix Blocks editor.
   * @returns {Promise} A promise that is resolved with the `appDefId` as a string.
   */
  function getAppDefId(): Promise<string> {
    return getAPI().then((api) => {
      return api.appBuilder.appInfo.getAppDefId()
    })
  }

  return {
    getAppName,
    getAppNamespace,
    appInfo: {
      getName: getAppName,
      getNamespace: getAppNamespace,
      getAppDefId: getAppDefId,
    },
    widgets: {
      getAllSerialized: getAllSerializedWidgets,
    },
    definitions: {
      getAllSerialized: getAllSerializedCustomDefinitions,
    },
  }
}
